import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import styles from './styles';

const useStyles = makeStyles(styles);

export const CakeV2Banner = memo(() => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.box}>
      <div className={`${classes.feature} ${classes.featureFirst}`}>
        <div className={classes.featureLabel}>
          {t('Vault-Banner-CakeV2-StakeLabel', { symbol: 'CAKE' })}
        </div>
        <div>Earning ZEFI auto compounding</div>
      </div>
      <div className={classes.logoFeature}>
        <div className={classes.logoHolder}>
          <img
            src={require('images/banners/CakeBanner.svg')}
            className={classes.logo}
            alt=""
            role="presentation"
            aria-hidden="true"
          />
        </div>
      </div>
      <div className={classes.feature}>
        <div className={classes.featureLabel}>more earnings</div>
        <div>
          Earnings are already automatically compounding,
          <br />
          there is no need to claim and manually compound
        </div>
      </div>
    </div>
  );
});
