import React, { memo, useState, useEffect, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { usePoolApr, useUserStaked } from '../../../../stake/redux/subscription';
import { useLaunchpoolSubscriptions } from '../../../../stake/redux/hooks';
import { getSingleAssetSrc } from '../../../../helpers/getSingleAssetSrc';

import styles from './styles';

const useStyles = makeStyles(styles);

export function useDataFunc(launchpoolId) {
  const { subscribe } = useLaunchpoolSubscriptions();
  if (launchpoolId) {
    subscribe(launchpoolId, {
      poolApr: true,
      poolStaked: true,
    });
  }

  const poolApr = usePoolApr(launchpoolId);
  const poolStaked = useUserStaked(launchpoolId);

  const response = {
    poolApr: poolApr,
    poolStaked: poolStaked,
  };

  return response;
}

const PoolTitle = ({
  name,
  logo,
  poolId,
  description,
  launchpool,
  buyTokenUrl,
  addLiquidityUrl,
  removeLiquidityUrl,
  assets,
  recentLaunchpools,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  let powersApr = [];
  let powersStake = [];
  let powersMore = [];

  const keyzefi = localStorage.getItem(`zefi_apy_day`);
  const keyzbo = localStorage.getItem(`zbo_apy_day`);

  // console.log('ZEFI : ' + (useDataFunc('zefi-zvcake-full').poolApr * 100))

  // ZEFI

  let dailyStakingReward = (useDataFunc('zefi-zvcake-full').poolApr * 100) / (365 * 100); // POOL
  let dailyPacocaReward = keyzefi / (1 * 100); // ZEFI

  // console.log('ZEFI : ' + dailyStakingReward)
  // console.log('ZEFI : ' + keyzefi)

  let sweetAPY = dailyStakingReward;

  for (const i of Array(364))
    sweetAPY = sweetAPY + sweetAPY * dailyPacocaReward + dailyStakingReward;

  let turbocakeAPR = (sweetAPY * 100).toFixed(2);

  if (sweetAPY * 100 > 0) {
    // console.log('ZEFI : ' + sweetAPY * 100)
    localStorage.setItem('apy_tcake_zefi', sweetAPY * 100); // key and value both should be string or number;
  }

  // ZBO

  let dailyStakingRewardZBO = (useDataFunc('zbo-zvcake-full').poolApr * 100) / (365 * 100); // POOL
  let dailyPacocaRewardZBO = keyzbo / (1 * 100); // ZEFI

  // console.log('ZBO : ' + dailyStakingRewardZBO)

  let sweetAPYZBO = dailyStakingRewardZBO;

  for (const i of Array(364))
    sweetAPYZBO = sweetAPYZBO + sweetAPYZBO * dailyPacocaRewardZBO + dailyStakingRewardZBO;

  let turbocakeAPRZBO = (sweetAPYZBO * 100).toFixed(2);

  // console.log('ZBO : ' + keyzbo)

  if (sweetAPYZBO * 100 > 0) {
    // console.log('ZBO : ' + sweetAPYZBO * 100)
    localStorage.setItem('apy_tcake_zbo', sweetAPYZBO * 100); // key and value both should be string or number;
  }

  powersApr = {
    'zefi-zvcake-full': turbocakeAPR,
    'zbo-zvcake-full': turbocakeAPRZBO,
    'zefi-zbo-lp2': (useDataFunc('zefi-zbo-lp2').poolApr * 100).toFixed(2),
    'goal-bnb-ula1': (useDataFunc('goal-bnb-ula1').poolApr * 100).toFixed(2),
    'zefi-chnd': (useDataFunc('zefi-chnd').poolApr * 100).toFixed(2),
  };

  powersStake = {
    'zefi-zvcake-full': useDataFunc('zefi-zvcake-full').poolStaked,
    'zbo-zvcake-full': useDataFunc('zbo-zvcake-full').poolStaked,
    'zefi-zbo-lp2': useDataFunc('zefi-zbo-lp2').poolStaked,
    'goal-bnb-ula1': useDataFunc('goal-bnb-ula1').poolStaked,
    'zefi-chnd': useDataFunc('zefi-chnd').poolStaked,
  };

  powersMore = {
    'zefi-zvcake-full': '',
    'zbo-zvcake-full': '',
    'zefi-zbo-lp2': '+',
    'goal-bnb-ula1': '+',
    'zefi-chnd': '+',
  };

  // const modifiedData = useModifiedData(launchpoolId)
  // console.log(powersApr)

  /*
  const segundos = 5;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const keydbAll = `update_apr`;
        const zefiDb = localStorage.getItem(keydbAll);
        let tempData;

        const agora = new Date().getTime();
        let ultimo;

        if (zefiDb === null) {
          ultimo = new Date().getTime();
        } else {
          ultimo = Number(zefiDb);
        }

        const diferenca = (agora - ultimo) / 1000;
        

      

        if (zefiDb === null || diferenca > segundos) {

        // localStorage.setItem(keydbAll, String(agora) );  // key and value both should be string or number;          

        // console.log('##################################')
        // console.log(agora)
        // console.log(ultimo)
        // console.log(diferenca)
        // console.log('##################################')        
        // console.log('Atualiza APR!!!')
        // console.log('##################################')
        // console.log( JSON.stringify(powersApr))
        // console.log('##################################')
        

            const response = await fetch(`https://site.zcore.fi/cron_apr.php`, {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                apr: JSON.stringify(powersApr),
              }),
            });
            const responsedata = await response.json();


          // setData(responsedata)
          // console.log(responsedata)

        } else {
          // console.log(`Aguarda API: ${diferenca}`)
        }
      } catch (error) {
        // console.error('Unable to fetch data:', error)
      }
    };

    fetchData();
  }, [powersApr]);
*/

  let powers = [];
  if (Object.keys(recentLaunchpools).length) {
    for (let i = 0; i < recentLaunchpools.length; i = i + 1) {
      if (recentLaunchpools[i].status == 'active') {
        const poolId = recentLaunchpools[i].id;
        // console.log(poolId)
        // const poolApr = useDataFunc(poolId)
        // console.log(powersStake[poolId].toNumber())

        if (powersStake[poolId]) {
          if (powersStake[poolId].toNumber() > 0) {
            powers.push(
              <a className={classes.btnBoost} href={'/stake/pool/' + recentLaunchpools[i].id}>
                <span>
                  <img alt="Fire" src={require('images/money.svg')} width={30} height={30} />
                  {powersApr[poolId] != Infinity
                    ? powersMore[poolId] + powersApr[poolId] + '%'
                    : '-'}
                </span>
              </a>
            );
          } else {
            powers.push(
              <a className={classes.btnBoost} href={'/stake/pool/' + recentLaunchpools[i].id}>
                <span>
                  <img alt="Fire" src={require('images/stake/fire.png')} width={30} height={30} />
                  {powersApr[poolId] != Infinity
                    ? powersMore[poolId] + powersApr[poolId] + '%'
                    : '-'}
                </span>
              </a>
            );
          }
        }
      }
    }
  }

  let avatar;
  if (logo) {
    avatar = (
      <Avatar
        alt={logo}
        variant="square"
        className={classes.icon}
        imgProps={{ style: { objectFit: 'contain' } }}
        src={require(`images/${logo}`)}
      />
    );
  } else {
    avatar = (
      <AvatarGroup className={`${classes.icon} MuiAvatar-root MuiAvatar-square`} spacing="small">
        <Avatar
          alt={assets[0]}
          variant="square"
          imgProps={{ style: { objectFit: 'contain' } }}
          src={getSingleAssetSrc(assets[0])}
        />
        <Avatar
          alt={assets[1]}
          variant="square"
          imgProps={{ style: { objectFit: 'contain' } }}
          src={getSingleAssetSrc(assets[1])}
        />
      </AvatarGroup>
    );
  }

  return (
    <Grid container wrap="nowrap">
      {avatar}
      <div className={classes.texts}>
        <Typography className={classes.title} variant="body2" gutterBottom>
          {poolId ? (
            <a href={`/vault/${poolId}`} className={classes.url}>
              {name}
            </a>
          ) : (
            name
          )}
        </Typography>
        <Typography className={classes.subtitle} variant="body2">
          {description}
        </Typography>
        <div style={{ display: 'flex', marginTop: '6px' }}>
          {buyTokenUrl ? (
            <a className={classes.url} href={buyTokenUrl} target="_blank" rel="noopener noreferrer">
              <span>{name === 'WBNB' ? t('Wrap-BNB') : t('Buy-Token')}</span>
              {'\u00A0\u00A0'}
            </a>
          ) : (
            ''
          )}
          {addLiquidityUrl ? (
            <a
              className={classes.url}
              href={addLiquidityUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>{t('Add-Liquidity')}</span>
            </a>
          ) : (
            ''
          )}
          {removeLiquidityUrl ? (
            <a
              className={classes.url}
              href={removeLiquidityUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>{t('Remove-Liquidity')}</span>
            </a>
          ) : (
            ''
          )}
        </div>
        {launchpool ? powers : ''}
      </div>
    </Grid>
  );
};

export default memo(PoolTitle);
