import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BigNumber from 'bignumber.js';
import {
  useFetchApproval,
  useFetchClaim,
  useFetchExit,
  useFetchStake,
  useFetchWithdraw,
  useLaunchpoolSubscriptions,
  useLaunchpoolUpdates,
  usePoolApr,
  usePoolFinish,
  usePoolStaked,
  usePoolStatus,
  useUserApproval,
  useUserBalance,
  useUserRewardsAvailable,
  useUserStaked,
} from '../redux/hooks';

import {
  Avatar,
  Box,
  Dialog,
  Grid,
  Link,
  makeStyles,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';

import Web3 from 'web3';
import { getRpcUrl } from 'common/networkSetup';

import TwitterIcon from '@material-ui/icons/Twitter';
import TelegramIcon from '@material-ui/icons/Telegram';

import { vaultABI } from '../../configure';
import Button from '../../../components/CustomButtons/Button';
import { styles } from './styles/view';
import Divider from '@material-ui/core/Divider';
import { formatApyNumber, formatApy, formatDecimals, formatPercent } from '../../helpers/format';
import { Helmet } from 'react-helmet';
import { usePageMeta } from '../../common/getPageMeta';
import { launchpools } from '../../helpers/getNetworkData';
import { useSelector } from 'react-redux';
import { StakeCountdown } from './StakeCountdown';
import ValueLoader from '../../common/components/ValueLoader/ValueLoader';
import { NetworkRequired } from '../../../components/NetworkRequired/NetworkRequired';
import { fetchPrice } from '../../web3';

const useStyles = makeStyles(styles);

export default function StakePool(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { fetchApproval } = useFetchApproval();
  const { fetchStake } = useFetchStake();
  const { fetchWithdraw } = useFetchWithdraw();
  const { fetchClaim } = useFetchClaim();
  const { fetchExit } = useFetchExit();
  const [showInput, setShowInput] = useState(false);
  const [inputVal, setInputVal] = useState(0);
  const [open, setOpen] = React.useState(false);
  const { getPageMeta } = usePageMeta();
  const theme = useTheme();
  const isNightMode = theme.palette.type === 'dark';

  // Get pool from url
  const poolId = props.match.params.id;

  const launchpool = useMemo(() => {
    return launchpools[poolId];
  }, [poolId]);

  // Subscribe to updates for this pool
  const { subscribe } = useLaunchpoolSubscriptions();
  useEffect(() => {
    return subscribe(launchpool.id, {
      userApproval: true,
      userBalance: true,
      userStaked: true,
      userRewardsAvailable: true,
      poolStaked: true,
      poolApr: true,
      poolFinish: true,
    });
  }, [subscribe, launchpool]);
  useLaunchpoolUpdates();

  // Get pool state
  const poolHideCountdown = launchpool.fixedStatus === true;
  const poolFinish = usePoolFinish(launchpool.id);
  const poolStatus = usePoolStatus(launchpool.id);
  const poolStaked = usePoolStaked(launchpool.id);
  let poolApr = usePoolApr(launchpool.id);
  const userApproval = useUserApproval(launchpool.id);
  const userBalance = useUserBalance(launchpool.id);
  const userStaked = useUserStaked(launchpool.id);
  const userRewardsAvailable = useUserRewardsAvailable(launchpool.id);

  // console.log(poolApr)
  // if ((poolApr = 'Infinity')) {
  // poolApr = 100;
  // }

  let isLocked = false;
  if (launchpool.isLocked && poolStatus != 'closed') {
    isLocked = true;
  }

  //console.log('isLocked: '+isLocked)

  function getPrice(token) {
    let pricePerFullShare = 0;

    const web3 = new Web3(new Web3.providers.HttpProvider(getRpcUrl()));
    const vault = new web3.eth.Contract(vaultABI, token);
    vault.methods.getPricePerFullShare().call(function (err, res) {
      if (err) {
        // console.log("An error occured", err)
        pricePerFullShare = 0;
        // console.log(pricePerFullShare)
      }
      pricePerFullShare = res;
      // console.log("The pricePerFullShare is: ", res)
      // console.log(pricePerFullShare)
    });

    return pricePerFullShare;
  }

  const fetchApprovalPending = useSelector(
    state => state.stake.fetchApprovalPending[launchpool.id]
  );
  const fetchStakePending = useSelector(state => state.stake.fetchStakePending[launchpool.id]);
  const fetchWithdrawPending = useSelector(
    state => state.stake.fetchWithdrawPending[launchpool.id]
  );
  const fetchClaimPending = useSelector(state => state.stake.fetchClaimPending[launchpool.id]);
  const fetchExitPending = useSelector(state => state.stake.fetchExitPending[launchpool.id]);

  // Countdown timer/status
  const countdownStatus = useMemo(() => {
    if (poolStatus === 'closed') {
      return <>{t('Finished')}</>;
    } else if (poolStatus === 'soon') {
      return <>{t('Coming-Soon')}</>;
    } else if (poolFinish && !poolHideCountdown) {
      return (
        <>
          {t('End') + ': '}
          <StakeCountdown periodFinish={poolFinish} />
        </>
      );
    } else if (poolFinish === undefined && !poolHideCountdown) {
      return <ValueLoader />;
    }

    return <></>;
  }, [poolStatus, poolHideCountdown, poolFinish, t]);

  // Pool Share
  const myPoolShare = useMemo(() => {
    return userStaked.dividedBy(poolStaked).toNumber();
  }, [userStaked, poolStaked]);

  // Modal input change
  const changeInputVal = event => {
    let value = event.target.value;
    const changeIsNumber = /^[0-9]+\.?[0-9]*$/;
    if (!value) return setInputVal(value);
    if (changeIsNumber.test(value)) {
      value = value.replace(/(^[0-9]+)(\.?[0-9]*$)/, (word, p1, p2) => {
        return Number(p1).toString() + p2;
      });
      if (
        new BigNumber(Number(value)).comparedTo(
          showInput === 'stake' ? userBalance : userStaked
        ) === 1
      )
        return setInputVal(showInput === 'stake' ? userBalance.toString() : userStaked.toString());
      setInputVal(value);
    }
  };

  // Approval: Needs approval
  const isNeedApproval = useMemo(() => {
    const stakeAmount = new BigNumber(inputVal);
    return (
      userApproval.isZero() || (showInput === 'stake' && stakeAmount.isGreaterThan(userApproval))
    );
  }, [userApproval, inputVal, showInput]);

  

   // Approval: Submit tx
   const onAuto = useCallback(() => {
    window.open("/vault/zcr-bnb-auto","_self")
  }, []);


  // Approval: Submit tx
  const onApproval = useCallback(() => {
    fetchApproval(poolId);
  }, [fetchApproval, poolId]);

  // Stake: Submit tx
  const onStake = useCallback(() => {
    const amount = new BigNumber(inputVal)
      .multipliedBy(new BigNumber(10).exponentiatedBy(launchpool.tokenDecimals))
      .toString(10);
    fetchStake(poolId, amount);
    setOpen(false);
  }, [fetchStake, inputVal, launchpool, poolId, setOpen]);

  // Withdraw: Submit tx
  const onWithdraw = useCallback(() => {
    const amount = new BigNumber(inputVal)
      .multipliedBy(new BigNumber(10).exponentiatedBy(launchpool.tokenDecimals))
      .toString(10);
    fetchWithdraw(poolId, amount);
    setOpen(false);
  }, [fetchWithdraw, inputVal, launchpool, poolId, setOpen]);

  // Claim: Submit tx
  const onClaim = useCallback(() => {
    fetchClaim(poolId);
  }, [fetchClaim, poolId]);

  // Exit: Submit tx
  const onExit = useCallback(() => {
    fetchExit(poolId);
  }, [fetchExit, poolId]);

  const handleModal = (state, action = false) => {
    setOpen(state);
    setShowInput(action);
    setInputVal(0);
  };

  const thisPriceFunc = async () => {
    if (launchpool.isMooReward) {
      let pricePerFullShare = 1;
      const web3 = new Web3(new Web3.providers.HttpProvider(getRpcUrl()));
      const vault = new web3.eth.Contract(vaultABI, launchpool.earnedTokenAddress);
      await vault.methods.getPricePerFullShare().call(function (err, res) {
        if (err) {
          // console.log("An error occured", err)
          pricePerFullShare = 1;
          // console.log(pricePerFullShare)
        }
        pricePerFullShare = res / 1e18;
        // console.log("The pricePerFullShare is: ", res)
        // console.log(pricePerFullShare)
      });
      // const thisPrice =  getPrice(launchpool.earnedTokenAddress)
      // console.log(pricePerFullShare)
      return pricePerFullShare;
    } else {
      return 1;
    }
  };

  const [thisPrice, setthisPrice] = useState(0);
  useEffect(() => {
    // Atualiza o titulo do documento usando a API do browser
    // document.title = `Você clicou ${count} vezes`;
    (async () => {
      setthisPrice(await thisPriceFunc());
    })();
  });
  // console.log(thisPrice)

  let valorRewards = 0;
  let valorRewardsPrice = 0;
  let renderThisPrice;
  let renderThisAPR;
  let renderThisValue;
  let renderThisInfo;
  let thisAPR, textAPR;
  const linkapr = 'https://club.zcore.network/eng#staking';
  const linkTokenReward = 'https://poocoin.app/tokens/' + launchpool.earnedTokenAddress;

  // console.log('##################################');
  // console.log(poolApr);
  // console.log(thisPrice);
  // console.log('##################################');

  if (launchpool.earnedOracleId === 'USDT') {
    renderThisPrice = formatDecimals(userRewardsAvailable);
    renderThisValue = launchpool.earnedToken;
    renderThisInfo = '';

    valorRewardsPrice = fetchPrice({ id: launchpool.earnedToken });
    valorRewards = valorRewardsPrice * renderThisPrice;

    //thisAPR = formatApy(poolApr);
    thisAPR = '1.5%';
    textAPR = 'ZCR REFLECTION';
    renderThisAPR = <Typography className={classes.subtitle}>{textAPR}</Typography>;
  }else if (thisPrice === 1 || launchpool.earnedOracleId === 'CHND') {
    renderThisPrice = formatDecimals(userRewardsAvailable);
    renderThisValue = launchpool.earnedToken;
    renderThisInfo = '';

    valorRewardsPrice = fetchPrice({ id: launchpool.earnedToken });
    valorRewards = valorRewardsPrice * renderThisPrice;

    thisAPR = formatApy(poolApr);
    textAPR = 'APR';
    renderThisAPR = <Typography className={classes.subtitle}>{textAPR}</Typography>;
  } else {
    // thisAPR = 0;
    textAPR = 'APY';
    renderThisAPR = <Typography className={classes.subtitle}>{textAPR}</Typography>;
    let keyzefi = 0;
    let linkpool;

    console.log(launchpool.id)

    if (launchpool.earnedOracleId === 'ZBO') {
      keyzefi = localStorage.getItem(`zbo_apy_day`);
      linkpool = '/vault/zbo-zbo';
      // } else if (launchpool.earnedOracleId === 'CHND') {
      //    keyzefi = localStorage.getItem(`chnd_apy_day`);
      //    linkpool = '/vault/chnd-chnd-auto';
    } else {
      keyzefi = localStorage.getItem(`zefi_apy_day`);
      linkpool = '/vault/zefi-zefi';
    }

    // console.log(launchpool.earnedOracleId + ' (keyzefi) : ' + keyzefi)
    // console.log(launchpool.earnedOracleId + ' (poolApr) : ' + poolApr)
    // console.log(launchpool.earnedOracleId + ' (formatApyNumber(poolApr) ): ' + formatApyNumber(poolApr))

    let dailyStakingReward = (poolApr * 100) / (365 * 100); // POOL
    let dailyPacocaReward = keyzefi / (1 * 100); // ZEFI

    let sweetAPY = dailyStakingReward;

    for (const i of Array(364))
      sweetAPY = sweetAPY + sweetAPY * dailyPacocaReward + dailyStakingReward;

    // console.log(launchpool.earnedOracleId + ' (sweetAPY) : ' + sweetAPY)

    if (launchpool.id === 'zeficard-zvzbo') {
      // TESTE
      // localStorage.setItem(`zeficard-zvzbo`, 3);
      localStorage.setItem(`zeficard-zvzbo`, (sweetAPY * 1000).toFixed(2));
      thisAPR = `${(sweetAPY * 1000).toFixed(2)}%`;
    } else {
      thisAPR = `${(sweetAPY * 100).toFixed(2)}%`;
    }

    let totRew = userRewardsAvailable * thisPrice;
    // console.log(totRew)
    // if(!totRew.isZero()){
    // totRew = formatDecimals(totRew)
    //

    renderThisInfo = (
      <Link href={linkpool}>
        <Typography className={classes.subtitle}>
          <i class="fa fa-external-link" aria-hidden="true"></i> Double Yield
        </Typography>
      </Link>
    );

    renderThisPrice = totRew.toFixed(8);
    renderThisValue = launchpool.earnedOracleId;

    valorRewardsPrice = fetchPrice({ id: launchpool.earnedToken });
    valorRewards = valorRewardsPrice * renderThisPrice;
  }

  if (launchpool.tokenOracleId == 'ZCRFMv3') {
    // console.log(launchpool.tokenOracleId);

    renderThisPrice = formatDecimals(userRewardsAvailable);
    renderThisValue = launchpool.earnedToken;
    renderThisInfo = '';

    valorRewardsPrice = fetchPrice({ id: launchpool.earnedToken });
    valorRewards = valorRewardsPrice * renderThisPrice;

    thisAPR = formatApy(poolApr);
    if (thisAPR === '🔥') {
      // thisAPR = "🐴";
      // textAPR = 'Each NFT has different APR';
      renderThisAPR = (
        <Link target="_blank" rel="noopener noreferrer" href={linkapr}>
          <Typography className={classes.subtitle}>
            <i class="fa fa-external-link" aria-hidden="true"></i> Each NFT has different APR
          </Typography>
        </Link>
      );
    } else {
      // textAPR = 'My NFTs ('+ localStorage.getItem(`tokensNFT`) +') APR';
      renderThisAPR = (
        <Link target="_blank" rel="noopener noreferrer" href={linkapr}>
          <Typography className={classes.subtitle}>
            <i class="fa fa-external-link" aria-hidden="true"></i> My NFTs (
            {localStorage.getItem(`tokensNFT`)}) APR
          </Typography>
        </Link>
      );
    }
  }

  let isla = 6;
  let isl = 3;
  let strUnstake = t('Stake-Button-Unstake-Tokens');
  let strClaimUn = t('Stake-Button-Exit');
  if (isLocked) {
    isl = 12;
    isla = 12;
    strUnstake = 'LP LOCKED';
    strClaimUn = 'LP LOCKED';
  }

  const customBgImg = img => {
    return img
      ? {
          backgroundImage: 'url(' + require('images/' + img) + ')',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }
      : {};
  };

  return (
    <Grid container>
      <Helmet>
        <title>
          {getPageMeta('Stake-Meta-Title', {
            earnedToken: launchpool.earnedToken,
            boostedBy: launchpool.name,
          })}
        </title>
        <meta
          property="og:title"
          content={getPageMeta('Stake-Meta-Title', {
            earnedToken: launchpool.earnedToken,
            boostedBy: launchpool.name,
          })}
        />
      </Helmet>
      <Grid item xs={6} className={classes.mb}>
        <Button href="/stake" className={classes.roundedBtn}>
          <i class="fa fa-chevron-circle-left" aria-hidden="true"></i>&nbsp;&nbsp;
          {t('Stake-Button-Back')}
        </Button>
      </Grid>
      <Grid item xs={6} className={classes.mb}>
        <Typography className={classes.countdown}>{countdownStatus}</Typography>
      </Grid>

      <Grid
        container
        className={[
          classes.row,
          poolStatus === 'closed' || poolStatus === 'soon' ? classes.retired : '',
        ].join(' ')}
        alignItems="center"
      >
        <Grid item xs={6} sm={6} md={3}>
          <Avatar
            src={require('images/' + launchpool.logo)}
            alt={launchpool.earnedToken}
            variant="square"
            imgProps={{ style: { objectFit: 'contain' } }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Typography className={classes.title}>
            <NetworkRequired loader>{formatDecimals(userBalance)}</NetworkRequired>
          </Typography>
          <Typography className={classes.tokenTitle}>{launchpool.token}</Typography>
          <Typography className={classes.subtitle}>{t('Vault-Wallet')}</Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Typography className={classes.title}>
            <NetworkRequired loader>{formatDecimals(userStaked)}</NetworkRequired>
          </Typography>
          <Typography className={classes.tokenTitle}>{launchpool.token}</Typography>
          <Typography className={classes.subtitle}>{t('Stake-Balancer-Current-Staked')}</Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={3}>
          <Typography className={classes.title}>
            <NetworkRequired loader>{renderThisPrice}</NetworkRequired>
          </Typography>
          <Typography className={classes.tokenTitle}>{renderThisValue}</Typography>
          <Typography className={classes.subtitle}>
            <a target="_blank" href={linkTokenReward}>
              Rewards (~${valorRewards.toFixed(2)})
            </a>
          </Typography>
          {renderThisInfo}
        </Grid>
      </Grid>

      <Grid
        container
        className={[
          classes.row,
          poolStatus === 'closed' || poolStatus === 'soon' ? classes.retired : '',
        ].join(' ')}
      >
        <Grid item xs={12} sm={4}>
          <Typography className={classes.title}>{formatDecimals(poolStaked)}</Typography>
          <Typography className={classes.subtitle}>
            {t('Stake-Total-Value-Locked', { mooToken: launchpool.token })}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography className={classes.title}>
            <NetworkRequired loader>{formatPercent(myPoolShare, 4)}</NetworkRequired>
          </Typography>
          <Typography className={classes.subtitle}>{t('Stake-Your-Pool')} (%)</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography className={classes.title}>{thisAPR}</Typography>
          {renderThisAPR}
        </Grid>

        {poolStatus === 'closed' || poolStatus === 'soon' ? (
          <Box className={classes.ribbon}>
            <span className={poolStatus}>
              {poolStatus === 'closed'
                ? t('Finished')
                : poolStatus === 'soon'
                ? t('Coming-Soon')
                : ''}
            </span>
          </Box>
        ) : (
          ''
        )}
      </Grid>

      <NetworkRequired>
        <Grid container className={classes.row}>
          {launchpool.partnership ? (
            <Box className={classes.boosted}>
              <i class="fas fa-rocket makeStyles-icon-1410" aria-hidden="true"></i> Power by{' '}
              {launchpool.name}
            </Box>
          ) : (
            ''
          )}
          <Grid item xs={12} md={isla} lg={isl}>
            {launchpool.id === "zcn-bnbbbbbbb" ? (
              <Button
              className={classes.actionBtn}
              disabled={fetchApprovalPending}
              onClick={onAuto}
            >
              {t('Stake Auto Compound')}
            </Button>
            ): (
            isNeedApproval ? (
              <Button
                className={classes.actionBtn}
                disabled={fetchApprovalPending}
                onClick={onApproval}
              >
                {t('Stake-Button-Approval')}
              </Button>
            ) : (
              <Button
                className={[classes.actionBtn, launchpool.partnership ? classes.btnBoost : ''].join(
                  ' '
                )}
                disabled={fetchStakePending || userBalance.isZero()}
                onClick={() => {
                  handleModal(true, 'stake');
                }}
              >
                {launchpool.partnership ? (
                  <Box className={classes.boost}>
                    <Box>
                      <Avatar
                        src={require('images/' + launchpool.logo)}
                        alt={launchpool.token}
                        variant="square"
                        imgProps={{ style: { objectFit: 'contain' } }}
                      />
                    </Box>
                    <Box>
                      <img
                        alt={t('Boost')}
                        className={classes.boostImg}
                        src={require('images/stake/boost.svg')}
                      />
                    </Box>
                  </Box>
                ) : (
                  t('Stake-Button-Stake-Tokens')
                )}
              </Button>
            )
            )}
          </Grid>
          {!isLocked ? (
            <>
              <Grid item xs={12} md={6} lg={3}>
                <Button
                  className={classes.actionBtn}
                  disabled={
                    isLocked || launchpool.isNft || fetchWithdrawPending || userStaked.isZero()
                  }
                  onClick={() => {
                    handleModal(true, 'unstake');
                  }}
                >
                  {strUnstake}
                </Button>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <Button
                  className={classes.actionBtn}
                  disabled={fetchClaimPending || userRewardsAvailable.isZero()}
                  onClick={onClaim}
                >
                  {t('Stake-Button-Claim-Rewards')}
                </Button>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <Button
                  className={classes.actionBtn}
                  disabled={
                    isLocked ||
                    launchpool.isNft ||
                    fetchExitPending ||
                    userStaked.isZero() ||
                    userRewardsAvailable.isZero()
                  }
                  onClick={onExit}
                >
                  {strClaimUn}
                </Button>
              </Grid>
            </>
          ) : (
            <Grid item xs={12} style={{ marginTop: '15px' }}>
              <strong>
                🔒 The deposited tokens and rewards are unlocked only after the end of the pool!
              </strong>
            </Grid>
          )}
          <Grid item xs={12} style={{ marginTop: '15px' }}>
            <Button target="_blank" href={launchpool.buyTokenUrl} className={classes.roundedBtn}>
              Get {launchpool.token}
            </Button>
          </Grid>
        </Grid>
      </NetworkRequired>

      {launchpool.partners.map(partner => (
        <Grid
          container
          key={partner.website}
          className={classes.row}
          style={customBgImg(partner.background)}
        >
          <Grid item xs={12} className={classes.partnerHeader}>
            {isNightMode && partner.logoNight ? (
              <img alt={launchpool.name} src={require('images/' + partner.logoNight)} height="60" />
            ) : partner.logo ? (
              <img alt={launchpool.name} src={require('images/' + partner.logo)} height="60" />
            ) : (
              ''
            )}
          </Grid>
          <Grid
            item
            xs={12}
            style={{ textAlignVertical: 'center', textAlign: 'center', whiteSpace: 'pre-line' }}
            className={classes.partnerBody}
          >
            {partner.text.split('<br/>').join('\n')}
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
            {partner.social.twitter ? (
              <Link href={partner.social.twitter}>
                <TwitterIcon />
              </Link>
            ) : (
              ''
            )}
            {partner.social.telegram ? (
              <Link href={partner.social.telegram}>
                <TelegramIcon />
              </Link>
            ) : (
              ''
            )}
            {partner.website ? (
              <Grid item xs={12}>
                <Link target="_blank" href={partner.website}>
                  {partner.website}
                </Link>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      ))}

      <Dialog
        onClose={() => {
          handleModal(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Grid container className={classes.modal}>
          <Grid item xs={12}>
            <Typography className={classes.h1}>Stake your tokens</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={classes.balance}
              onClick={() => {
                setInputVal(showInput === 'stake' ? userBalance.toString() : userStaked.toString());
              }}
            >
              {launchpool.token} Balance:{' '}
              {showInput === 'stake' ? userBalance.toString() : userStaked.toString()}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form noValidate autoComplete="off">
              <TextField
                className={classes.input}
                value={inputVal}
                onChange={changeInputVal}
                autoFocus
              />
            </form>
          </Grid>
          <Grid item xs={12} className={classes.modalbtns}>
            <Button onClick={() => handleModal(false)} className={classes.actionBtn}>
              <i class="fa fa-chevron-circle-left" aria-hidden="true"></i>&nbsp;&nbsp;
              {t('Stake-Button-Back')}
            </Button>
            <Button
              className={[
                classes.actionBtn,
                launchpool.partnership && showInput === 'stake' && !isNeedApproval
                  ? classes.btnBoost
                  : '',
              ].join(' ')}
              disabled={
                showInput === 'stake'
                  ? fetchStakePending || (fetchApprovalPending && isNeedApproval)
                  : fetchWithdrawPending
              }
              onClick={showInput === 'stake' ? (isNeedApproval ? onApproval : onStake) : onWithdraw}
            >
              {showInput === 'stake' ? (
                isNeedApproval ? (
                  t('Stake-Button-Approval')
                ) : launchpool.partnership ? (
                  <Box className={classes.boost}>
                    <Box>
                      <Avatar
                        src={require('images/' + launchpool.logo)}
                        alt={launchpool.earnedToken}
                        variant="square"
                        imgProps={{ style: { objectFit: 'contain' } }}
                      />
                    </Box>
                    <Box>
                      <img
                        alt={t('Boost')}
                        className={classes.boostImg}
                        src={require('images/stake/boost.svg')}
                      />
                    </Box>
                  </Box>
                ) : (
                  t('Stake-Button-Stake-Tokens')
                )
              ) : (
                t('Stake-Button-Unstake-Tokens')
              )}
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </Grid>
  );
}
