import { createMuiTheme } from '@material-ui/core/styles';

const createTheme = isNightMode =>
  createMuiTheme({
    palette: {
      type: isNightMode ? 'dark' : 'light',
      background: {
        default: isNightMode ? '#000000' : '#f1f0f0',
        paper: isNightMode ? '#198774' : '#fff',
        primary: isNightMode ? '#116254' : '#f3fbf0',
        secondary: isNightMode ? '#198774' : '#ecf8ec',
        extra: isNightMode ? '#116254' : '#f2fbf0',
        dark: isNightMode ? '#0b3f36' : '#999',
        paused: isNightMode ? '#116254' : '#FCE57E',
        retired: isNightMode ? '#d32f2f' : '#e57373',
        hover: isNightMode ? '#0b846f' : '#ddefdc',
        border: isNightMode ? '#0b846f' : '#d5ded6',
        button: isNightMode ? '#ff9933' : '#116254',
        overlay: isNightMode ? 'rgba(0, 0, 0, 0.75)' : 'rgba(255, 255, 255, 0.75)',
      },
      primary: {
        main: isNightMode ? '#fff' : '#000',
      },
      secondary: {
        main: isNightMode ? '#fff' : '#ecf8ec',
      },
      text: {
        primary: isNightMode ? '#fff' : '#000',
        secondary: isNightMode ? '#d6d7d7' : '#00000066',
      },
    },
    overrides: {
      MuiButton: {
        label: {
          color: isNightMode ? '#fff' : '#000',
        },
      },
      // for dropdown menu items
      MuiButtonBase: {
        root: {
          color: isNightMode ? '#fff' : '#000',
        },
      },
      MuiCheckbox: {
        colorPrimary: {
          color: isNightMode ? '#fff' : '#000',
        },
        colorSecondary: {
          color: isNightMode ? '#fff' : '#000',
        },
      },
    },
  });

export default createTheme;
