import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import styles from './styles';

const useStyles = makeStyles(styles);

const Disclaimer = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return null;

  {
    /*
  return (
    <Grid container item className={classes.root} justify="center">
      <a
        target="_blank"
        href="https://bscscan.com/token/0x0288d3e353fe2299f11ea2c2e1696b4a648ecc07?a=0x000000000000000000000000000000000000dead"
      >
        <Typography className={classes.disclaimer}>
          🔥&nbsp;&nbsp;All fees received are automatically buyback ZEFI and burned{' '}
          <i class="fa fa-external-link" aria-hidden="true"></i>
        </Typography>
      </a>
    </Grid>
  );
*/
  }
};

export default memo(Disclaimer);
