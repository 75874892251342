import BigNumber from 'bignumber.js';

export const fetchAllowance = async ({ web3, address, contract, contractAddress }) => {
  if (!contract) {
    return '115792089237316195423570985008687907853269984665640564039457584007913129639935';
  }
  const balance = await contract.methods
    .allowance(address, contractAddress)
    .call({ from: address });
  const allowance = web3.utils.fromWei(balance, 'ether');
  return new BigNumber(allowance).toNumber();
};
