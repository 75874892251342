import { govPoolABI } from '../abi';
const govPoolABIZefi = require('../govPoolABIZefi.json');

export const polygonStakePools = [
  {
    id: 'zefi-polygon',
    name: 'ZEFI',
    logo: 'single-assets/ZEFI.png',
    token: 'ZEFI',
    tokenDecimals: 18,
    tokenAddress: '0xCaf870DaD882b00F4b20D714Bbf7fceADA5E4195',
    tokenOracle: 'tokens',
    tokenOracleId: 'ZEFIM',
    earnedToken: 'MATIC',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    earnContractAddress: '0xb8EF489e3E62Af68CA4B62C4f669a026EDE6aDBe',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'WMATIC',
    partnership: false,
    status: 'active',
    fixedStatus: true,
    buyTokenUrl:
      'https://quickswap.exchange/#/swap?inputCurrency=ETH&outputCurrency=0xCaf870DaD882b00F4b20D714Bbf7fceADA5E4195',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  {
    id: 'zefi-zefi-power',
    name: 'ZCore Finance',
    logo: 'single-assets/ZEFI.png',
    token: 'zvZEFI',
    tokenDecimals: 18,
    tokenAddress: '0xcaee82cC440398AF0ed0c7343331147121c88998',
    tokenOracle: 'tokens',
    tokenOracleId: 'ZEFIM',
    earnedToken: 'ZEFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xCaf870DaD882b00F4b20D714Bbf7fceADA5E4195',
    earnContractAddress: '0x2F19E49ba711654EDDbab60f54fF08d539AE6BAE',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZEFIM',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1628524419,
    buyTokenUrl: '/vault/polygon-zefi',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  {
    id: 'zefi-zefi-power2',
    name: 'ZCore Finance',
    logo: 'single-assets/ZEFI.png',
    token: 'zvZEFI',
    tokenDecimals: 18,
    tokenAddress: '0xcaee82cC440398AF0ed0c7343331147121c88998',
    tokenOracle: 'tokens',
    tokenOracleId: 'ZEFIM',
    earnedToken: 'ZEFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xCaf870DaD882b00F4b20D714Bbf7fceADA5E4195',
    earnContractAddress: '0xb7D20Be82400a58B7980A18553e06E8f32Fa0469',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZEFIM',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1629229205,
    buyTokenUrl: '/vault/polygon-zefi',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },
  /* FIX */
  {
    id: 'zefi-zefi-power3',
    name: 'ZCore Finance',
    logo: 'single-assets/ZEFI.png',
    token: 'zvZEFI',
    tokenDecimals: 18,
    tokenAddress: '0xcaee82cC440398AF0ed0c7343331147121c88998',
    tokenOracle: 'tokens',
    tokenOracleId: 'ZEFIM',
    earnedToken: 'ZEFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xCaf870DaD882b00F4b20D714Bbf7fceADA5E4195',
    earnContractAddress: '0x2667D1C69E8671d3cA97b06FA8E0340069149FAa',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZEFIM',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1629814536,
    buyTokenUrl: '/vault/polygon-zefi',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  {
    id: 'zefi-zefi-power4',
    name: 'ZCore Finance',
    logo: 'single-assets/ZEFI.png',
    token: 'zvZEFI',
    tokenDecimals: 18,
    tokenAddress: '0xcaee82cC440398AF0ed0c7343331147121c88998',
    tokenOracle: 'tokens',
    tokenOracleId: 'ZEFIM',
    earnedToken: 'ZEFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xCaf870DaD882b00F4b20D714Bbf7fceADA5E4195',
    earnContractAddress: '0x269D9559cE577D89cD09927eF0A8F74c96B8c444',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZEFIM',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1630433964,
    buyTokenUrl: '/vault/polygon-zefi',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  {
    id: 'zefi-zefi-power5',
    name: 'ZCore Finance',
    logo: 'single-assets/ZEFI.png',
    token: 'zvZEFI',
    tokenDecimals: 18,
    tokenAddress: '0xcaee82cC440398AF0ed0c7343331147121c88998',
    tokenOracle: 'tokens',
    tokenOracleId: 'ZEFIM',
    earnedToken: 'ZEFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xCaf870DaD882b00F4b20D714Bbf7fceADA5E4195',
    earnContractAddress: '0x5c4A36257c53F068a154E8b1Ea3168ab2E7283C1',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZEFIM',
    partnership: true,
    status: 'active',
    isMooStaked: true,
    periodFinish: 1631161575,
    buyTokenUrl: '/vault/polygon-zefi',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  {
    id: 'zefi-matic-lp',
    name: 'ZCore Finance',
    logo: 'degens/ZEFI-MATIC.png',
    token: 'ZEFI-MATIC',
    tokenDecimals: 18,
    tokenAddress: '0xD6BC1680Fe03A55d564130B36B5A11b8BbDed365',
    tokenOracle: 'lps',
    tokenOracleId: 'zefi-matic',
    earnedToken: 'ZEFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xCaf870DaD882b00F4b20D714Bbf7fceADA5E4195',
    earnContractAddress: '0x5FBA94A8F31Bb7360e620899E16D038a462f27e7',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZEFIM',
    partnership: false,
    status: 'closed',
    isMooStaked: false,
    periodFinish: 1627941013,
    buyTokenUrl: 'https://quickswap.exchange/#/add/ETH/0xCaf870DaD882b00F4b20D714Bbf7fceADA5E4195',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  {
    id: 'zefi-matic-lp2',
    name: 'ZCore Finance',
    logo: 'degens/ZEFI-MATIC.png',
    token: 'ZEFI-MATIC',
    tokenDecimals: 18,
    tokenAddress: '0xD6BC1680Fe03A55d564130B36B5A11b8BbDed365',
    tokenOracle: 'lps',
    tokenOracleId: 'zefi-matic',
    earnedToken: 'ZEFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xCaf870DaD882b00F4b20D714Bbf7fceADA5E4195',
    earnContractAddress: '0xe3775A017f5577688Ecbab6c96c3af2C33eA974A',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZEFIM',
    partnership: false,
    status: 'closed',
    isMooStaked: false,
    periodFinish: 1628523751,
    buyTokenUrl: 'https://quickswap.exchange/#/add/ETH/0xCaf870DaD882b00F4b20D714Bbf7fceADA5E4195',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  {
    id: 'zefi-matic-lp3',
    name: 'ZCore Finance',
    logo: 'degens/ZEFI-MATIC.png',
    token: 'ZEFI-MATIC',
    tokenDecimals: 18,
    tokenAddress: '0xD6BC1680Fe03A55d564130B36B5A11b8BbDed365',
    tokenOracle: 'lps',
    tokenOracleId: 'zefi-matic',
    earnedToken: 'ZEFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xCaf870DaD882b00F4b20D714Bbf7fceADA5E4195',
    earnContractAddress: '0x0bcC09490D55FD3c8027378875ec517F6450281d',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZEFIM',
    partnership: false,
    status: 'closed',
    isMooStaked: false,
    periodFinish: 1629231144,
    buyTokenUrl: 'https://quickswap.exchange/#/add/ETH/0xCaf870DaD882b00F4b20D714Bbf7fceADA5E4195',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  {
    id: 'zefi-matic-lp4',
    name: 'ZCore Finance',
    logo: 'degens/ZEFI-MATIC.png',
    token: 'ZEFI-MATIC',
    tokenDecimals: 18,
    tokenAddress: '0xD6BC1680Fe03A55d564130B36B5A11b8BbDed365',
    tokenOracle: 'lps',
    tokenOracleId: 'zefi-matic',
    earnedToken: 'ZEFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xCaf870DaD882b00F4b20D714Bbf7fceADA5E4195',
    earnContractAddress: '0xC763337048Ab89747Bc8de70d633171fcCEe9186',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZEFIM',
    partnership: false,
    status: 'closed',
    isMooStaked: false,
    periodFinish: 1629814896,
    buyTokenUrl: 'https://quickswap.exchange/#/add/ETH/0xCaf870DaD882b00F4b20D714Bbf7fceADA5E4195',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  {
    id: 'zefi-matic-lp5',
    name: 'ZCore Finance',
    logo: 'degens/ZEFI-MATIC.png',
    token: 'ZEFI-MATIC',
    tokenDecimals: 18,
    tokenAddress: '0xD6BC1680Fe03A55d564130B36B5A11b8BbDed365',
    tokenOracle: 'lps',
    tokenOracleId: 'zefi-matic',
    earnedToken: 'ZEFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xCaf870DaD882b00F4b20D714Bbf7fceADA5E4195',
    earnContractAddress: '0x5e4c56BED6883Ca897F5A95e7c79FfcaB18e83B3',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZEFIM',
    partnership: false,
    status: 'closed',
    isMooStaked: false,
    periodFinish: 1630434257,
    buyTokenUrl: 'https://quickswap.exchange/#/add/ETH/0xCaf870DaD882b00F4b20D714Bbf7fceADA5E4195',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  {
    id: 'zefi-matic-lp6',
    name: 'ZCore Finance',
    logo: 'degens/ZEFI-MATIC.png',
    token: 'ZEFI-MATIC',
    tokenDecimals: 18,
    tokenAddress: '0xD6BC1680Fe03A55d564130B36B5A11b8BbDed365',
    tokenOracle: 'lps',
    tokenOracleId: 'zefi-matic',
    earnedToken: 'ZEFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xCaf870DaD882b00F4b20D714Bbf7fceADA5E4195',
    earnContractAddress: '0xF5499DA127EA5d22A8309b0985645c381F434Cec',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZEFIM',
    partnership: false,
    status: 'active',
    isMooStaked: false,
    periodFinish: 1631852008,
    buyTokenUrl: 'https://quickswap.exchange/#/add/ETH/0xCaf870DaD882b00F4b20D714Bbf7fceADA5E4195',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  {
    id: 'zefi-matic-lp7',
    name: 'ZCore Finance',
    logo: 'degens/ZEFI-MATIC.png',
    token: 'ZEFI-MATIC',
    tokenDecimals: 18,
    tokenAddress: '0xD6BC1680Fe03A55d564130B36B5A11b8BbDed365',
    tokenOracle: 'lps',
    tokenOracleId: 'zefi-matic',
    earnedToken: 'ZEFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xCaf870DaD882b00F4b20D714Bbf7fceADA5E4195',
    earnContractAddress: '0x5Fb71fBceE628314ebef94FaC1d173480A33e4Fb',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZEFIM',
    partnership: false,
    status: 'active',
    isMooStaked: false,
    periodFinish: 1637332958,
    buyTokenUrl: 'https://quickswap.exchange/#/add/ETH/0xCaf870DaD882b00F4b20D714Bbf7fceADA5E4195',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  /* 
POWER ZEFI PRINCIPAL
EARN ZEFI

{
    id: 'zefi-zefi-power',
    name: 'ZCore Finance',
    logo: 'single-assets/ZEFI.png',
    token: 'zvZEFI',
    tokenDecimals: 18,
    tokenAddress: '0xcaee82cC440398AF0ed0c7343331147121c88998',
    tokenOracle: 'tokens',
    tokenOracleId: 'ZEFIM',
    earnedToken: 'ZEFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xCaf870DaD882b00F4b20D714Bbf7fceADA5E4195',
    earnContractAddress: '0x0d063adCEf9f9A4d08c245365205e8d8309A8878',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZEFIM',
    partnership: true,
    status: 'active',
    isMooStaked: true,
    periodFinish: 1628151307,
    buyTokenUrl: '/vault/polygon-zefi',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },
*/

  /* 
POWER ZEFI PRINCIPAL
EARN zvZEFI

{
    id: 'zefi-zefi-power-zv',
    name: 'ZCore Finance',
    logo: 'single-assets/ZEFI.png',
    token: 'zvZEFI',
    tokenDecimals: 18,
    tokenAddress: '0xcaee82cC440398AF0ed0c7343331147121c88998',
    tokenOracle: 'tokens',
    tokenOracleId: 'ZEFIM',
    earnedToken: 'zvZEFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xcaee82cC440398AF0ed0c7343331147121c88998',
    earnContractAddress: '0x9B60C79c9eF35ceD98bd489b3E82df936e895c00',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZEFIM',
    partnership: true,
    status: 'active',
    isMooStaked: true,
    isMooReward: true,    
    periodFinish: 1628153101,
    buyTokenUrl: '/vault/polygon-zefi',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

*/

  /*
    {
    id: 'zefi-matic-zefi',
    name: 'ZCore Finance',
    logo: 'degens/ZEFI-MATIC.png',
    token: 'zvZEFI-MATIC',
    tokenDecimals: 18,
    tokenAddress: '0x37970637A6b3FA9924C274c9CFc47C0C430d7864',
    tokenOracle: 'tokens',
    tokenOracleId: 'zefi-matic',
    earnedToken: 'ZEFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xCaf870DaD882b00F4b20D714Bbf7fceADA5E4195',
    earnContractAddress: '0x1F64Efbd1f1985fE8d0bAA1E13788e84FE702dFE',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZEFIM',
    partnership: true,
    status: 'closed',
    isMooStaked: false,
    periodFinish: 1627087711,
    buyTokenUrl: '/vault/zefi-matic',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },
  */
];
