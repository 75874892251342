import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import styles from './styles';

const useStyles = makeStyles(styles);

const PoolPaused = ({ message, isBoosted, isExperimental, isTurbo }) => {
  const classes = useStyles();

  return (
    <div
      style={{ borderTopLeftRadius: '8px' }}
      className={[
        classes.container,
        isTurbo
          ? classes.turbo
          : isBoosted
          ? classes.launchpool
          : isExperimental
          ? classes.experimental
          : '',
      ].join(' ')}
    >
      <div className={classes.texts}>{message}</div>
    </div>
  );
};

export default memo(PoolPaused);
