const styles = theme => ({
  container: {
    paddingTop: '4px',
  },
  cardprinc: {
    textAlign: 'center',
    marginBottom: '10px',
    padding: '10px',
    boxShadow: '3px 3px 3px #9E9E9E',
    borderRadius: '10px',
    alignItems: 'center',
    elevation: 0,
    background: theme.palette.background.primary,
  },
  tvl: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    boxShadow: '3px 3px 3px #9E9E9E',
    borderRadius: '10px',
    paddingTop: '20px',
    paddingRight: '20px',
    marginBottom: '10px',
    backgroundImage: "url('/images/2c.png'), linear-gradient(#6eb260, #0f5f54)",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top left',
  },
  titlenet: {
    fontSize: '18px',
    letterSpacing: '0',
    lineHeight: '32px',
    fontWeight: '550',
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: '34px',
    letterSpacing: '0',
    lineHeight: '34px',
    fontWeight: '550',
    // color: theme.palette.text.primary,
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
  },
  titleLoader: {
    marginLeft: '12px',
  },
  subtitle: {
    letterSpacing: '0',
    lineHeight: '8px',
    [theme.breakpoints.down('xs')]: {
      lineHeight: '16px',
    },
    color: '#DDDDDD',
    marginTop: '0',
  },
  text: {
    fontSize: '20px',
    letterSpacing: '0',
    lineHeight: '32px',
    fontWeight: '550',
    color: '#f3fbf0',
    display: 'flex',
    alignItems: 'center',
  },
  infinityIcon: {
    marginBottom: '-6px',
    paddingRight: '5px',
  },
});

export default styles;
