import { govPoolABI } from '../abi';
const govPoolABIZefi = require('../govPoolABIZefi.json');

export const bscStakePools = [
  {
    id: 'zcn-zcn-appv3',
    name: 'ZCore',
    logo: 'single-assets/NZCR.svg',
    token: 'ZCR',
    tokenDecimals: 18,
    tokenAddress: '0x0e8fE6eeD5342Ea9189D9268D088821f0532fb74',
    tokenOracle: 'tokens',
    tokenOracleId: 'NZCR',
    earnedToken: 'USDT',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x55d398326f99059fF775485246999027B3197955',
    earnContractAddress: '0x45bF809485B2b72D279082A23AA97B2eB74bDF7a',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'USDT',
    isLocked: false,
    partnership: false,
    status: 'active',
    isMooStaked: false,
    isMooReward: false,
    periodFinish: 1702487904,
    fixedStatus: true,
    buyTokenUrl:
      'https://pancakeswap.finance/swap?outputCurrency=0x0e8fe6eed5342ea9189d9268d088821f0532fb74',
      partners: [
        {
          logo: 'single-assets/NZCR.svg',
          background: 'stake/zcorenetwork/backgroundv2.png',
          text: 'This pool is exclusively designated for receiving the ZCR reflection percentage. For more details, please visit the following website:',
          website: 'https://docs.zcore.network/english/zcore-zcr-token-bsc/dao-new-zcore-zcr-contract-on-binance-smart-chain-bsc',
          social: {
            telegram: 'https://t.me/ZCoreMiners',
            twitter: 'https://twitter.com/zcorecrypto',
          },
        },
      ],
  },
  {
    id: 'zcn-zcn-appv2',
    name: 'ZCore',
    logo: 'single-assets/NZCR.svg',
    token: 'ZCR',
    tokenDecimals: 18,
    tokenAddress: '0x0e8fE6eeD5342Ea9189D9268D088821f0532fb74',
    tokenOracle: 'tokens',
    tokenOracleId: 'NZCR',
    earnedToken: 'ZCR',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x0e8fE6eeD5342Ea9189D9268D088821f0532fb74',
    earnContractAddress: '0x24790EBBF5eBE1d73D6d5d0FD52D2AC21d2EeCB7',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'NZCR',
    isLocked: false,
    partnership: false,
    status: 'active',
    isMooStaked: false,
    isMooReward: false,
    periodFinish: 1702487904,
    fixedStatus: false,
    buyTokenUrl:
      'https://bscscan.com/address/0x0e8fe6eed5342ea9189d9268d088821f0532fb74',
      partners: [
        {
          logo: 'single-assets/NZCR.svg',
          background: 'stake/zcorenetwork/backgroundv2.png',
          text: 'Introducing the staking for ZCore (ZCR) new contract. If you wish to swap your ZCR for the new contract, please use the swap dApp available at the following link:',
          website: 'https://zcore.network/swap',
          social: {
            telegram: 'https://t.me/ZCoreMiners',
            twitter: 'https://twitter.com/zcorecrypto',
          },
        },
      ],
  },

  {
    id: 'zcnv2-bnb',
    name: 'ZCore',
    logo: 'single-assets/NZCR-BNB.png',
    token: 'ZCR-BNB',
    tokenDecimals: 18,
    tokenAddress: '0x83A5D4A893b9a78439e6Dc499cD331D1185B7609',
    tokenOracle: 'lps',
    tokenOracleId: 'nzcr-bnb',
    earnedToken: 'ZCR',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x0e8fE6eeD5342Ea9189D9268D088821f0532fb74',
    earnContractAddress: '0x660a641a7fe595601aa2bd72eDB3BFbfd487880c',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'NZCR',
    isLocked: false,
    partnership: false,
    status: 'active',
    isMooStaked: false,
    isMooReward: false,
    periodFinish: 1710358224,
    fixedStatus: false,
    buyTokenUrl:
      'https://bscscan.com/address/0x83a5d4a893b9a78439e6dc499cd331d1185b7609',
      partners: [
        {
          logo: 'single-assets/NZCR.svg',
          background: 'stake/zcorenetwork/backgroundv2.png',
          text: 'Introducing the LP staking for ZCore (ZCR) new contract. If you wish to swap your ZCR for the new contract, please use the swap dApp available at the following link:',
          website: 'https://zcore.network/swap',
          social: {
            telegram: 'https://t.me/ZCoreMiners',
            twitter: 'https://twitter.com/zcorecrypto',
          },
        },
      ],
  },
  {
    id: 'zcn-zcn-app',
    name: 'ZCore',
    logo: 'single-assets/ZCR-logo.png',
    token: 'ZCR',
    tokenDecimals: 18,
    tokenAddress: '0x2E40565771cf2BF8622C41CDd85701519F22418A',
    tokenOracle: 'tokens',
    tokenOracleId: 'ZCR',
    earnedToken: 'ZCR',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x2E40565771cf2BF8622C41CDd85701519F22418A',
    earnContractAddress: '0xe899C6a9CC6663e5b678c0602f9a21BF1F2Bff16',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZCR',
    isLocked: false,
    partnership: false,
    status: 'closed',
    isMooStaked: false,
    isMooReward: false,
    periodFinish: 1688645476,
    fixedStatus: false,
    buyTokenUrl:
      'https://pancakeswap.finance/swap?outputCurrency=0x2E40565771cf2BF8622C41CDd85701519F22418A',
      partners: [],
  },
  
  {
    id: 'zcn-bnb',
    name: 'ZCore',
    logo: 'single-assets/ZCR-BNB.png',
    token: 'ZCR-BNB',
    tokenDecimals: 18,
    tokenAddress: '0x6eF1dE5cB0ce29EdaFb361E5B86948A2aaFcF31C',
    tokenOracle: 'lps',
    tokenOracleId: 'zcr-bnb',
    earnedToken: 'ZCR',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x2E40565771cf2BF8622C41CDd85701519F22418A',
    earnContractAddress: '0x1DFF21a92b296693Ee964a76f53C5e26116aCAe5',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZCR',
    isLocked: false,
    partnership: false,
    status: 'closed',
    isMooStaked: false,
    isMooReward: false,
    periodFinish: 1683423525,
    fixedStatus: false,
    buyTokenUrl:
      'https://exchange.zcore.network/#/add/BNB/0x2E40565771cf2BF8622C41CDd85701519F22418A',
    partners: [],
  },


  {
    id: 'zcn-busd',
    name: 'ZCore',
    logo: 'single-assets/ZCR-BUSD.png',
    token: 'ZCR-BUSD',
    tokenDecimals: 18,
    tokenAddress: '0xB66aA380F83e1e16C33dA315c3aC662493183a65',
    tokenOracle: 'lps',
    tokenOracleId: 'zcr-busd',
    earnedToken: 'ZCR',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x2E40565771cf2BF8622C41CDd85701519F22418A',
    earnContractAddress: '0x45d78D940896D54F5fBf56322c794Ac29983484a',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZCR',
    isLocked: false,
    partnership: false,
    status: 'closed',
    isMooStaked: false,
    isMooReward: false,
    periodFinish: 1698076889,
    fixedStatus: false,
    buyTokenUrl:
      'https://exchange.zcore.network/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x2E40565771cf2BF8622C41CDd85701519F22418A',
    partners: [],
  },
/*

  

  */
  
{
  id: 'zbo-zbo-full',
  name: 'ZCore Finance',
  logo: 'single-assets/ZBO.svg',
  token: 'ZBO',
  tokenDecimals: 18,
  tokenAddress: '0x7D3550d0B0aC3590834cA6103907CD6Dd41318f8',
  tokenOracle: 'tokens',
  tokenOracleId: 'ZBO',
  earnedToken: 'ZBO',
  earnedTokenDecimals: 18,
  earnedTokenAddress: '0x7D3550d0B0aC3590834cA6103907CD6Dd41318f8',
  earnContractAddress: '0x34828cBE073b8ef56Ec129533a065835E6597267',
  earnContractAbi: govPoolABIZefi,
  earnedOracle: 'tokens',
  earnedOracleId: 'ZBO',
  partnership: false,
  status: 'closed',
  isMooStaked: false,
  isMooReward: false,
  fixedStatus: false,
  periodFinish: 1694736107,
  buyTokenUrl:
    'https://exchange.zcore.network/#/swap?inputCurrency=ETH&outputCurrency=0x7D3550d0B0aC3590834cA6103907CD6Dd41318f8',
  partners: [
    {
      logo: 'stake/zcore/logo.png',
      background: 'stake/zcore/background.png',
      text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
      website: 'https://finance.zcore.network/',
      social: {
        telegram: 'https://t.me/ZCoreFinance',
        twitter: 'https://twitter.com/ZCoreCrypto',
      },
    },
  ],
},
  {
    id: 'zcn-zcn-2',
    name: 'ZCore',
    logo: 'single-assets/ZCR-logo.png',
    token: 'ZCR',
    tokenDecimals: 18,
    tokenAddress: '0x2E40565771cf2BF8622C41CDd85701519F22418A',
    tokenOracle: 'tokens',
    tokenOracleId: 'ZCR',
    earnedToken: 'ZCR',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x2E40565771cf2BF8622C41CDd85701519F22418A',
    earnContractAddress: '0x562BbB911318406D8aDBd26072f0E4DaA7049722',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZCR',
    isLocked: true,
    partnership: false,
    status: 'closed',
    isMooStaked: false,
    isMooReward: false,
    periodFinish: 1688645476,
    fixedStatus: false,
    buyTokenUrl:
      'https://pancakeswap.finance/swap?outputCurrency=0x2E40565771cf2BF8622C41CDd85701519F22418A',
    partners: [
      {
        logo: 'stake/zcorenetwork/logo.png',
        background: 'stake/zcorenetwork/background.png',
        text: 'This ZCR is the native coin of the new ZCN Network which will be launched in Q2 2023.<br/>⚠️ See the WZCR (old) swap process and deadline clicking on the website link.<br/>🕐 Pool End: 90 days',
        website: 'https://zcore.network/swap.html',
        social: {
          telegram: 'https://t.me/ZCoreMiners',
          twitter: 'https://twitter.com/zcorecrypto',
        },
      },
    ],
  },

  {
    id: 'zcn-zcn',
    name: 'ZCore',
    logo: 'single-assets/ZCR.png',
    token: 'ZCR',
    tokenDecimals: 18,
    tokenAddress: '0x2E40565771cf2BF8622C41CDd85701519F22418A',
    tokenOracle: 'tokens',
    tokenOracleId: 'ZCR',
    earnedToken: 'ZCR',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x2E40565771cf2BF8622C41CDd85701519F22418A',
    earnContractAddress: '0xEe00cc04d95ad5720B3A888223d0a162B48F9CEA',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZCR',
    isLocked: true,
    partnership: false,
    status: 'closed',
    isMooStaked: false,
    isMooReward: false,
    periodFinish: 1683424479,
    fixedStatus: false,
    buyTokenUrl:
      'https://pancakeswap.finance/swap?outputCurrency=0x2E40565771cf2BF8622C41CDd85701519F22418A',
    partners: [
      {
        logo: 'stake/zcorenetwork/logo.png',
        background: 'stake/zcorenetwork/background.png',
        text: 'This ZCR is the native coin of the new ZCN Network which will be launched in Q2 2023.<br/>⚠️ See the WZCR (old) swap process and deadline clicking on the website link.<br/>🕐 Pool End: 30 days',
        website: 'https://zcore.network/swap.html',
        social: {
          telegram: 'https://t.me/ZCoreMiners',
          twitter: 'https://twitter.com/zcorecrypto',
        },
      },
    ],
  },
  /*
  {
    id: 'zcn-bnb',
    name: 'ZCore',
    logo: 'single-assets/ZCR-BNB.png',
    token: 'ZCR-BNB',
    tokenDecimals: 18,
    tokenAddress: '0x6eF1dE5cB0ce29EdaFb361E5B86948A2aaFcF31C',
    tokenOracle: 'lps',
    tokenOracleId: 'zcr-bnb',
    earnedToken: 'ZCR',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x2E40565771cf2BF8622C41CDd85701519F22418A',
    earnContractAddress: '0x9905E661E2772F119D6D95D400D519965EadF98F',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZCR',
    isLocked: true,
    partnership: false,
    status: 'active',
    isMooStaked: false,
    isMooReward: false,
    periodFinish: 1683423525,
    fixedStatus: false,
    buyTokenUrl: 'https://pancakeswap.finance/add/0x2E40565771cf2BF8622C41CDd85701519F22418A/BNB',
    partners: [
      {
        logo: 'stake/zcorenetwork/logo.png',
        background: 'stake/zcorenetwork/background.png',
        text: 'The new ZCore Network (ZCN) will be released in Q2 2023.<br/>⚠️ See the WZCR (old) swap process and deadline clicking on the website link.<br/>🕐 Pool End: 30 days',
        website: 'https://zcore.network/swap.html',
        social: {
          telegram: 'https://t.me/ZCoreMiners',
          twitter: 'https://twitter.com/zcorecrypto',
        },
      },
    ],
  },
*/
  {
    id: 'zcrfmv1-zvzbo4',
    name: 'ZCore Finance',
    logo: 'single-assets/ZCRFMv1.png',
    token: 'ZCRFMv3',
    tokenDecimals: 18,
    tokenAddress: '0x818aB38D88dDCbEDbff8673Fb8733Ec6d59AcAc9',
    tokenOracle: 'tokens',
    tokenOracleId: 'ZCRFMv3',
    earnedToken: 'WZCR',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x1F01Dc57C66C2f87D8eab9C625D335e9defE6912',
    earnContractAddress: '0x1160b362d3d2f66E605AcD8a534Bffc8358d28B6',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'WZCR',
    isNft: false,
    isLocked: true,
    partnership: false,
    status: 'closed',
    isMooStaked: false,
    isMooReward: false,
    periodFinish: 1680878336,
    fixedStatus: false,
    buyTokenUrl: 'https://join.zcore.network/',
    partners: [
      {
        logo: 'stake/zcorenetwork/logo.png',
        background: 'stake/zcorenetwork/background.png',
        text: 'The new ZCore Network (ZCN) will be released in Q2 2023.<br/>⚠️ See the ZCR (old) swap process and deadline clicking on the website link.<br/>🕐 Pool End: 90 days',
        website: 'https://zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreMiners',
          twitter: 'https://twitter.com/zcorecrypto',
        },
      },
    ],
  },

  {
    id: 'wzcr-wzcr',
    name: 'ZCore',
    logo: 'single-assets/WZCR.png',
    token: 'WZCR',
    tokenDecimals: 18,
    tokenAddress: '0x1F01Dc57C66C2f87D8eab9C625D335e9defE6912',
    tokenOracle: 'tokens',
    tokenOracleId: 'WZCR',
    earnedToken: 'WZCR',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x1F01Dc57C66C2f87D8eab9C625D335e9defE6912',
    earnContractAddress: '0x85Fed3e093CBE74bd6b1bF8e1A4a58dF9E556D2d',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'WZCR',
    isLocked: true,
    partnership: false,
    status: 'closed',
    isMooStaked: false,
    isMooReward: false,
    periodFinish: 1680892487,
    fixedStatus: false,
    buyTokenUrl: 'https://pancakeswap.finance/add/0x1F01Dc57C66C2f87D8eab9C625D335e9defE6912/BNB',
    partners: [
      {
        logo: 'stake/zcorenetwork/logo.png',
        background: 'stake/zcorenetwork/background.png',
        text: 'The new ZCore Network (ZCN) will be released in Q2 2023.<br/>⚠️ See the ZCR (old) swap process and deadline clicking on the website link.<br/>🕐 Pool End: 90 days',
        website: 'https://zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreMiners',
          twitter: 'https://twitter.com/zcorecrypto',
        },
      },
    ],
  },

  {
    id: 'wzcr-bnb',
    name: 'ZCore',
    logo: 'single-assets/WZCR-BNB.png',
    token: 'WZCR-BNB',
    tokenDecimals: 18,
    tokenAddress: '0xC1C12b87a1042382052CAE555001c1fA77B201a9',
    tokenOracle: 'lps',
    tokenOracleId: 'wzcr-bnb',
    earnedToken: 'WZCR',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x1F01Dc57C66C2f87D8eab9C625D335e9defE6912',
    earnContractAddress: '0x791261607685f4240dFA061C3913ab035D228A07',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'WZCR',
    isLocked: true,
    partnership: false,
    status: 'closed',
    isMooStaked: false,
    isMooReward: false,
    periodFinish: 1680878237,
    fixedStatus: false,
    buyTokenUrl: 'https://pancakeswap.finance/add/0x1F01Dc57C66C2f87D8eab9C625D335e9defE6912/BNB',
    partners: [
      {
        logo: 'stake/zcorenetwork/logo.png',
        background: 'stake/zcorenetwork/background.png',
        text: 'The new ZCore Network (ZCN) will be released in Q2 2023.<br/>⚠️ See the ZCR (old) swap process and deadline clicking on the website link.<br/>🕐 Pool End: 90 days',
        website: 'https://zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreMiners',
          twitter: 'https://twitter.com/zcorecrypto',
        },
      },
    ],
  },

  {
    id: 'zcrfmv1-zvzbo3',
    name: 'ZCore Finance',
    logo: 'single-assets/ZCRFMv1.png',
    token: 'ZCRFMv3',
    tokenDecimals: 18,
    tokenAddress: '0x818aB38D88dDCbEDbff8673Fb8733Ec6d59AcAc9',
    tokenOracle: 'tokens',
    tokenOracleId: 'ZCRFMv3',
    earnedToken: 'ZBO',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x7d3550d0b0ac3590834ca6103907cd6dd41318f8',
    earnContractAddress: '0x7B2C48DAeA24B3341c22CF396004101095368b05',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZBO',
    isNft: false,
    partnership: false,
    status: 'closed',
    isMooStaked: false,
    isMooReward: false,
    periodFinish: 1673048320,
    fixedStatus: false,
    buyTokenUrl: 'https://join.zcore.network/',
    partners: [
      {
        logo: 'stake/zcoreclub/logo.png',
        background: 'stake/zcoreclub/background.png',
        text: 'ZCore Farm Horses is the official collection of NFTs from the ZCore Network (ZCN) compost of 10,000 digital artworks with unique characteristics: 20 legendary NFTs and thousands with many accessories in many categories and different levels of rarity , which will guarantee advantages, whether in income, raffles, in the power of governance votes (DAO) and in many actions of the club.',
        website: 'https://club.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  {
    id: 'zcrfmv1-zvzbo2',
    name: 'ZCore Finance',
    logo: 'single-assets/ZCRFMv1.png',
    token: 'ZCRFMv2',
    tokenDecimals: 18,
    tokenAddress: '0xfaE433Bd83dbC3654E1cbb3e6F187F2cDDfb3b8b',
    tokenOracle: 'tokens',
    tokenOracleId: 'ZCRFMv2',
    earnedToken: 'ZBO',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x7d3550d0b0ac3590834ca6103907cd6dd41318f8',
    earnContractAddress: '0x414dA4c311a9F4A8E388516adAe1a4E6e5c1f250',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZBO',
    isNft: true,
    partnership: false,
    status: 'closed',
    isMooStaked: false,
    isMooReward: false,
    periodFinish: 1661460192,
    fixedStatus: false,
    buyTokenUrl: 'https://join.zcore.network/',
    partners: [
      {
        logo: 'stake/zcoreclub/logo.png',
        background: 'stake/zcoreclub/background.png',
        text: 'ZCore Farm Horses is the official collection of NFTs from the ZCore Network (ZCN) compost of 10,000 digital artworks with unique characteristics: 20 legendary NFTs and thousands with many accessories in many categories and different levels of rarity , which will guarantee advantages, whether in income, raffles, in the power of governance votes (DAO) and in many actions of the club.',
        website: 'https://club.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },
  {
    id: 'zcrfmv1-zvzbo',
    name: 'ZCore Finance',
    logo: 'single-assets/ZCRFMv1.png',
    token: 'ZCRFMv1',
    tokenDecimals: 18,
    tokenAddress: '0x12C1e1987205671BdD27E92Cb00Dd179F066Fda3',
    tokenOracle: 'tokens',
    tokenOracleId: 'ZCRFMv1',
    earnedToken: 'ZBO',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x7d3550d0b0ac3590834ca6103907cd6dd41318f8',
    earnContractAddress: '0x9A37425a258680a58238eF513a2322464d155c5D',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZBO',
    isNft: true,
    partnership: false,
    status: 'closed',
    isMooStaked: false,
    isMooReward: false,
    periodFinish: 1661460192,
    fixedStatus: false,
    buyTokenUrl: 'https://join.zcore.network/',
    partners: [
      {
        logo: 'stake/zcoreclub/logo.png',
        background: 'stake/zcoreclub/background.png',
        text: 'ZCore Farm Horses is the official collection of NFTs from the ZCore Network (ZCN) compost of 10,000 digital artworks with unique characteristics: 20 legendary NFTs and thousands with many accessories in many categories and different levels of rarity , which will guarantee advantages, whether in income, raffles, in the power of governance votes (DAO) and in many actions of the club.',
        website: 'https://club.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },
  /*
  {
    id: 'zcrfmv1-zvzbo',
    name: 'ZCore Finance',
    logo: 'single-assets/ZCRFMv1.png',
    token: 'ZCRFMv1',
    tokenDecimals: 18,
    tokenAddress: '0x1e97787C61a93861E16d227f038a3A374c521B80',
    tokenOracle: 'tokens',
    tokenOracleId: 'ZCRFMv1',
    earnedToken: 'zvZBO',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x5310dDC64F2EA3cbafE433C2bEEE18d5DF89f1ca',
    earnContractAddress: '0xE3f352F9AB562DA496f458e2Fc432498F2e490Bc',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZBO',
    partnership: false,
    status: 'closed',
    isMooStaked: false,
    isMooReward: true,
    periodFinish: 1657637828,
    fixedStatus: false,
    buyTokenUrl: 'https://club.zcore.network/',
    partners: [],
  },
  */
  {
    id: 'zeficard-zvzbo',
    name: 'ZCore Finance',
    logo: 'single-assets/ZEFICARD.png',
    token: 'ZEFICARD',
    tokenDecimals: 18,
    tokenAddress: '0x1d71aEed05f64132ce2005E290D2295104e15332',
    tokenOracle: 'tokens',
    tokenOracleId: 'ZEFI',
    earnedToken: 'zvZBO',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x5310dDC64F2EA3cbafE433C2bEEE18d5DF89f1ca',
    earnContractAddress: '0x8cA2FC4AD12eD3E7dcA3Fbe8b617AA79283ec606',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZBO',
    partnership: false,
    status: 'closed',
    isMooStaked: false,
    isMooReward: true,
    periodFinish: 1657637828,
    fixedStatus: false,
    buyTokenUrl: 'https://t.me/ZCoreFinancePT',
    partners: [],
  },
  {
    id: 'zefi-chnd',
    name: 'CashHand',
    logo: 'single-assets/ZEFI.svg',
    token: 'zvZEFI',
    tokenDecimals: 18,
    tokenAddress: '0xB869e8b466d16f499039F377C0642e169a7bA698',
    tokenOracle: 'tokens',
    tokenOracleId: 'ZEFI',
    earnedToken: 'zvCHND',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x3238A0df494631bEC0c1a6a1214c8Cb0A332b862',
    earnContractAddress: '0x975ff15f7E2bA981276416cfEc38186CBC0A7AB2',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'CHND',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    isMooReward: true,
    periodFinish: 1645832513,
    buyTokenUrl: '/vault/zefi-zefi',
    partners: [],
  },


  {
    id: 'zefi-zvcake-full',
    name: 'ZCore Finance',
    logo: 'degens/cake.svg',
    token: 'zvaultCAKE',
    tokenDecimals: 18,
    tokenAddress: '0x820f518027d908FcE5dfD6439A64fc9aB141061d',
    tokenOracle: 'tokens',
    tokenOracleId: 'Cake',
    earnedToken: 'zvZEFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xB869e8b466d16f499039F377C0642e169a7bA698',
    earnContractAddress: '0xC12E2D9Ed64713F85DDC3a9e59710fF2098016A1',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZEFI',
    partnership: false,
    status: 'closed',
    isMooStaked: true,
    isMooReward: true,
    fixedStatus: true,
    periodFinish: 9999999999,
    buyTokenUrl: '/vault/cake-cakev2',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  {
    id: 'zbo-zvcake-full',
    name: 'ZCore Finance',
    logo: 'degens/cake.svg',
    token: 'zvaultCAKE',
    tokenDecimals: 18,
    tokenAddress: '0x820f518027d908FcE5dfD6439A64fc9aB141061d',
    tokenOracle: 'tokens',
    tokenOracleId: 'Cake',
    earnedToken: 'zvZBO',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x5310dDC64F2EA3cbafE433C2bEEE18d5DF89f1ca',
    earnContractAddress: '0xE635f209990570aA598eFaFD0BcD7A49dB89A0A9',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZBO',
    partnership: false,
    status: 'closed',
    isMooStaked: true,
    isMooReward: true,
    fixedStatus: true,
    periodFinish: 9999999999,
    buyTokenUrl: '/vault/zbo-zbo',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  {
    id: 'iqcoin-zvcake',
    name: 'IQCoin Finance',
    logo: 'degens/cake.svg',
    token: 'zvaultCAKE',
    tokenDecimals: 18,
    tokenAddress: '0x820f518027d908FcE5dfD6439A64fc9aB141061d',
    tokenOracle: 'tokens',
    tokenOracleId: 'Cake',
    earnedToken: 'IQCOIN',
    earnedTokenDecimals: 9,
    earnedTokenAddress: '0xc530fb1008ef5bafdf8f4ab589b69de837e41169',
    earnContractAddress: '0xE603EAD00454280C182385075f1f0feF028BCcb1',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'IQCOIN',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    isMooReward: false,
    periodFinish: 9999999999,
    buyTokenUrl: '/vault/cake-cakev2',
    partners: [
      {
        logo: 'stake/iqcoin/logo.png',
        background: 'stake/iqcoin/background.png',
        text: 'IQCoin is a deflationary, solid, robust and transparent project within the Smart Contract BSC Platform. It is the first teaching token aimed at the financial market, nft games and metaverse with proposals for social projects and an innovative marketplace.',
        website: 'http://iqcoin.finance/',
        social: {
          telegram: 'https://t.me/Iqcoinoficial',
          twitter: 'https://twitter.com/IQCOINTOKEN',
        },
      },
    ],
  },

  {
    id: 'chnd-chnd',
    name: 'ChasHand',
    logo: 'single-assets/WCHND.png',
    token: 'CHND',
    tokenDecimals: 18,
    tokenAddress: '0xA0451f8820CFb45CEd3A90470901e1dB38fb7b8f',
    tokenOracle: 'tokens',
    tokenOracleId: 'CHND',
    earnedToken: 'CHND',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xA0451f8820CFb45CEd3A90470901e1dB38fb7b8f',
    earnContractAddress: '0x64c4FDF269201A474943AB43849FdEAF32e7344e',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'CHND',
    partnership: false,
    status: 'closed',
    isMooStaked: false,
    isMooReward: false,
    periodFinish: 2420239224,
    fixedStatus: true,
    buyTokenUrl:
      'https://pancakeswap.finance/swap?outputCurrency=0xA0451f8820CFb45CEd3A90470901e1dB38fb7b8f',
    partners: [],
  },

  {
    id: 'chnd-bnb',
    name: 'ChasHand',
    logo: 'single-assets/WCHND-BNB.png',
    token: 'CHND-BNB',
    tokenDecimals: 18,
    tokenAddress: '0x5d89bBc415EC545E1a5d0E0C5aAd9a4989C86334',
    tokenOracle: 'lps',
    tokenOracleId: 'chnd-bnb',
    earnedToken: 'CHND',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xA0451f8820CFb45CEd3A90470901e1dB38fb7b8f',
    earnContractAddress: '0xC3B55c4225b43277238cbf11E8F5aE1fBa9c32e5',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'CHND',
    partnership: false,
    status: 'closed',
    isMooStaked: false,
    isMooReward: false,
    periodFinish: 2420237541,
    fixedStatus: true,
    buyTokenUrl: 'https://pancakeswap.finance/add/0xA0451f8820CFb45CEd3A90470901e1dB38fb7b8f/BNB',
    partners: [],
  },

  {
    id: 'wchnd-bnb',
    name: 'Wrapped ChasHand',
    logo: 'single-assets/WCHND-BNB.png',
    token: 'WCHND-BNB',
    tokenDecimals: 18,
    tokenAddress: '0x1f052C6Aad5CF0e925147d30F29E30732EF9825e',
    tokenOracle: 'tokens',
    tokenOracleId: 'wchnd-bnb',
    earnedToken: 'WCHND',
    earnedTokenDecimals: 8,
    earnedTokenAddress: '0x3EA8941e69Cc1494Efc41495a75caBfa1f3737c4',
    earnContractAddress: '0x339FDbEac2F92acBC894F4C4e05Ce96b7A35A5a7',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'WCHND',
    partnership: false,
    status: 'closed',
    isMooStaked: false,
    isMooReward: false,
    periodFinish: 1656445345,
    buyTokenUrl: 'https://pancakeswap.finance/add/0x3EA8941e69Cc1494Efc41495a75caBfa1f3737c4/BNB',
    partners: [],
  },

  {
    id: 'zefi-zvcake5',
    name: 'ZCore Finance',
    logo: 'degens/cake.svg',
    token: 'zvaultCAKE',
    tokenDecimals: 18,
    tokenAddress: '0x820f518027d908FcE5dfD6439A64fc9aB141061d',
    tokenOracle: 'tokens',
    tokenOracleId: 'Cake',
    earnedToken: 'zvZEFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xB869e8b466d16f499039F377C0642e169a7bA698',
    earnContractAddress: '0x1C1351C9AfD6d1a4B32414896298B3e07Af3cbaB',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZEFI',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    isMooReward: true,
    periodFinish: 1634703668,
    buyTokenUrl: '/vault/cake-cakev2',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  {
    id: 'zefi-zvcake4',
    name: 'ZCore Finance',
    logo: 'degens/cake.svg',
    token: 'zvaultCAKE',
    tokenDecimals: 18,
    tokenAddress: '0x820f518027d908FcE5dfD6439A64fc9aB141061d',
    tokenOracle: 'tokens',
    tokenOracleId: 'Cake',
    earnedToken: 'zvZEFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xB869e8b466d16f499039F377C0642e169a7bA698',
    earnContractAddress: '0x1B6FFca975CC9AA740ab9A8ccab3f4CFca2d9949',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZEFI',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    isMooReward: true,
    periodFinish: 1634421250,
    buyTokenUrl: '/vault/cake-cakev2',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },
  {
    id: 'zefi-zvcake6',
    name: 'Ysoychain',
    logo: 'degens/cake.svg',
    token: 'zvaultCAKE',
    tokenDecimals: 18,
    tokenAddress: '0x820f518027d908FcE5dfD6439A64fc9aB141061d',
    tokenOracle: 'tokens',
    tokenOracleId: 'Cake',
    earnedToken: 'ySOY',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x57488Fcc3dC72Edb0a4c06a356c2c43C08BdfB42',
    earnContractAddress: '0x4C9B3b09C728B91fB5371179b9D573A4091dbce7',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ySOY',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1634085796,
    buyTokenUrl: '/vault/cake-cakev2',
    partners: [
      {
        logo: 'stake/ysoychain/logo.png',
        background: 'stake/ysoychain/background.png',
        text: 'Ysoy chain protocols derived from soybean chain,Binance Smart Chain protocols BEP-20 Symbols: Ysoy, yTofu, ySoymilk, ySoybean oil',
        website: 'https://ysoychain.farm',
        social: {
          telegram: 'https://t.me/ysoychain',
          twitter: 'https://twitter.com/FarmYsoy',
        },
      },
    ],
  },
  {
    id: 'zefi-zvcake3',
    name: 'ZCore Finance',
    logo: 'degens/cake.svg',
    token: 'zvaultCAKE',
    tokenDecimals: 18,
    tokenAddress: '0x820f518027d908FcE5dfD6439A64fc9aB141061d',
    tokenOracle: 'tokens',
    tokenOracleId: 'Cake',
    earnedToken: 'ZBO',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x7D3550d0B0aC3590834cA6103907CD6Dd41318f8',
    earnContractAddress: '0x45fd150a2e0BDB2b8010529F73BF14dF9b933a10',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZBO',
    partnership: false,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1635515901,
    buyTokenUrl: '/vault/cake-cakev2',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },
  /*
  {
    id: 'zefi-zvcake2',
    name: 'ZCore Finance',
    logo: 'degens/cake.svg',
    token: 'zvaultCAKE',
    tokenDecimals: 18,
    tokenAddress: '0x820f518027d908FcE5dfD6439A64fc9aB141061d',
    tokenOracle: 'tokens',
    tokenOracleId: 'Cake',
    earnedToken: 'ZEFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
    earnContractAddress: '0x70da018a4Dd90A586B948b22339d559B58BF0027',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZEFI',
    partnership: false,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1633405241,
    buyTokenUrl: '/vault/cake-cakev2',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  {
    id: 'zefi-zvcake',
    name: 'ZCore Finance',
    logo: 'degens/cake.svg',
    token: 'zvaultCAKE',
    tokenDecimals: 18,
    tokenAddress: '0x820f518027d908FcE5dfD6439A64fc9aB141061d',
    tokenOracle: 'tokens',
    tokenOracleId: 'Cake',
    earnedToken: 'ZEFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
    earnContractAddress: '0x9b07E8eBd34D23154FF0654a1C6de825a26Ab6b3',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZEFI',
    partnership: false,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1633402992,
    buyTokenUrl: '/vault/cake-cakev2',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },
*/
  {
    id: 'zefi-btcb-zefi22',
    name: 'Zaigar Finance',
    logo: 'degens/ZEFI-BTCB.png',
    token: 'zvZEFI-BTCB',
    tokenDecimals: 18,
    tokenAddress: '0xD398415ed07829BD55eB08793d3e3E309cD1399d',
    tokenOracle: 'tokens',
    tokenOracleId: 'zefi-btcb',
    earnedToken: 'ZAIF',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x280C3Fc949b1a1D7a470067cA6F7b48b3CB219c5',
    earnContractAddress: '0xd1A719c0fC03670b69b4a98B9b829472211d1048',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZAIF',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1630467093,
    buyTokenUrl: '/vault/zefi-btcb',
    partners: [
      {
        logo: 'stake/zaigar/logo.png',
        background: 'stake/zaigar/background.png',
        text: 'Zaigar is a cryptocurrency rewards marketing company that has been in the market for over 3 years and has more than 30,000 users around the world, having already provided services to large companies in this new economy. With the aim of always continuing to support the development of the cryptocurrency industry, we are now officially launching our DeFi (Decentralized Finance) platform, Zaigar Finance, implemented within the Binance Smart Chain (BSC) network. A simple, fast, and secure platform that allows all members of our community to carry out operations with cryptocurrencies in an extremely easy and uncomplicated way. Furthermore, by adopting well-known strategies in the DeFi universe, it has an economic model capable of generating rewards in crypto assets through Farming and Staking.',
        website: 'https://zaigar.finance/',
        social: {
          telegram: 'https://t.me/zaigarbounty',
          twitter: 'https://twitter.com/ZaigarF',
        },
      },
    ],
  },

  {
    id: 'zefi-bnb-ula2',
    name: 'Fanatics Finance',
    logo: 'degens/ZEFI-BNB.png',
    token: 'zvZEFIBNB',
    tokenDecimals: 18,
    tokenAddress: '0xEFb3D0152ceE0415Eb578eb0CD25ffF4c310503A',
    tokenOracle: 'tokens',
    tokenOracleId: 'zefiv2-zefi-bnb',
    earnedToken: 'ULA',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x5ced02730f98554c7a64d7365788cf7b2c549b2b',
    earnContractAddress: '0xc0990b460619539B2489c20DC0dc52675caAaE14',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ULA',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1630445392,
    buyTokenUrl: '/vault/zefiv2-zefi-bnb',
    partners: [
      {
        logo: 'stake/fanatics/logo.png',
        background: 'stake/fanatics/background.png',
        text: 'Fanatics is a exclusive DeFi for fan tokens, football club tokens and sports entities. All football fanatics now have their place reserved in the DeFi gallery!',
        website: 'https://fanaticsfinance.com/',
        social: {
          telegram: 'https://t.me/fanaticsfinance_EN',
          twitter: 'https://twitter.com/fanaticsfinance',
        },
      },
    ],
  },

  {
    id: 'zefi-eth-zefi1',
    name: 'ZCore Finance',
    logo: 'degens/ZEFI-ETH.png',
    token: 'zvZEFI-ETH',
    tokenDecimals: 18,
    tokenAddress: '0x64B70aE347b30b52365EA8233368E21e8ac4f982',
    tokenOracle: 'tokens',
    tokenOracleId: 'zefi-eth',
    earnedToken: 'ZEFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
    earnContractAddress: '0xc8aca9ec6758b9C88d72362e0e6AD6C9892eA1b8',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZEFI',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1629850189,
    buyTokenUrl: '/vault/zefi-eth',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  {
    id: 'zefi-btcb-zefi1',
    name: 'ZCore Finance',
    logo: 'degens/ZEFI-BTCB.png',
    token: 'zvZEFI-BTCB',
    tokenDecimals: 18,
    tokenAddress: '0xD398415ed07829BD55eB08793d3e3E309cD1399d',
    tokenOracle: 'tokens',
    tokenOracleId: 'zefi-btcb',
    earnedToken: 'ZEFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
    earnContractAddress: '0xfA3F40C5f549da04f0CaeD3e352f16F99C0b1254',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZEFI',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1629850201,
    buyTokenUrl: '/vault/zefi-btcb',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  {
    id: 'zefi-bnb-axs1',
    name: 'ZCore Finance',
    logo: 'degens/ZEFI-BNB.png',
    token: 'zvZEFIBNB',
    tokenDecimals: 18,
    tokenAddress: '0xEFb3D0152ceE0415Eb578eb0CD25ffF4c310503A',
    tokenOracle: 'tokens',
    tokenOracleId: 'zefiv2-zefi-bnb',
    earnedToken: 'AXS',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x715D400F88C167884bbCc41C5FeA407ed4D2f8A0',
    earnContractAddress: '0x974979d7689df95b6efaE9Bd5eF8D4Ff303cf8Ec',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'AXS',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1629829159,
    buyTokenUrl: '/vault/zefiv2-zefi-bnb',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  {
    id: 'zefi-bnb-zvzefi1',
    name: 'ZCore Finance',
    logo: 'degens/ZEFI-BNB.png',
    token: 'zvZEFIBNB',
    tokenDecimals: 18,
    tokenAddress: '0xEFb3D0152ceE0415Eb578eb0CD25ffF4c310503A',
    tokenOracle: 'tokens',
    tokenOracleId: 'zefiv2-zefi-bnb',
    earnedToken: 'zvZEFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xB869e8b466d16f499039F377C0642e169a7bA698',
    earnContractAddress: '0xD9DEF3b1f3C5D5a2AA3e89cbb88B0db9Bc4F688c',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZEFI',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    isMooReward: true,
    periodFinish: 1631164991,
    buyTokenUrl: '/vault/zefiv2-zefi-bnb',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  /*
 {
    id: 'zefi-test2',
    name: 'ZEFI',
    logo: 'degens/zefi.png',
    token: 'ZEFI',
    tokenDecimals: 18,
    tokenAddress: '0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
    tokenOracle: 'tokens',
    tokenOracleId: 'ZEFI',
    earnedToken: 'TEST2',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xe0D2a193E44A4d0341A20A0BFDD1AE7588612c8d',
    earnContractAddress: '0x4D9Eb648A3C693c5d93EFfdb1dBb40cBE3c35650',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'TEST2',
    partnership: false,
    status: 'closed',
    hideCountdown: true,
    partners: [
      {
        logo: 'stake/beefy/beefyfinance.png',
        background: 'stake/beefy/background.png',
        text: "You probably already knew that Beefy is the most trusted Yield optimizer for the Binance Smart Chain. But did you know that Beefy has its own token? $BIFI has a maximum supply of 80000 tokens and there is no way to mint more. Everyone who holds our own $BIFI token can not only do cool stuff like create and vote on proposals, they also get a share of all harvests done, every hour, every day on all our +120 vaults. That's a lot of BNB that goes straight to our $BIFI holders. All you have to do is stake your $BIFI in this vault, it’s that simple, come back and harvest your BNB whenever you need it! (You can still vote on proposals even though you have staked your $BIFI here).",
        website: 'https://app.beefy.finance',
        social: {
          telegram: 'http://t.me/beefyfinance',
          twitter: 'https://twitter.com/beefyfinance',
        },
      },
    ],
  },
 {
    id: 'zefi-test3',
    name: 'ZEFI',
    logo: 'degens/zefi.png',
    token: 'ZEFI',
    tokenDecimals: 18,
    tokenAddress: '0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
    tokenOracle: 'tokens',
    tokenOracleId: 'ZEFI',
    earnedToken: 'TEST2',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xe0D2a193E44A4d0341A20A0BFDD1AE7588612c8d',
    earnContractAddress: '0xc111129db079DaBfcec8B42887f8ba8ec6318Beb',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'TEST2',
    partnership: false,
    status: 'closed',
    hideCountdown: true,
    partners: [
      {
        logo: 'stake/beefy/beefyfinance.png',
        background: 'stake/beefy/background.png',
        text: "You probably already knew that Beefy is the most trusted Yield optimizer for the Binance Smart Chain. But did you know that Beefy has its own token? $BIFI has a maximum supply of 80000 tokens and there is no way to mint more. Everyone who holds our own $BIFI token can not only do cool stuff like create and vote on proposals, they also get a share of all harvests done, every hour, every day on all our +120 vaults. That's a lot of BNB that goes straight to our $BIFI holders. All you have to do is stake your $BIFI in this vault, it’s that simple, come back and harvest your BNB whenever you need it! (You can still vote on proposals even though you have staked your $BIFI here).",
        website: 'https://app.beefy.finance',
        social: {
          telegram: 'http://t.me/beefyfinance',
          twitter: 'https://twitter.com/beefyfinance',
        },
      },
    ],
  },  
  {
    id: 'zefi-test1',
    name: 'ZCore Finance',
    logo: 'degens/test2.png',
    token: 'ZEFI',
    tokenDecimals: 18,
    tokenAddress: '0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
    tokenOracle: 'tokens',
    tokenOracleId: 'TEST2',
    earnedToken: 'TEST2',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xe0D2a193E44A4d0341A20A0BFDD1AE7588612c8d',
    earnContractAddress: '0x25D5d39D1d08DCd5623C1735Ad479279E6e3cCF6',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'TEST2',
    partnership: false,
    status: 'closed',
    isMooStaked: false,
    periodFinish: 1625445542,
    partners: [
      {
        logo: 'stake/test2/logo.png',
        background: 'stake/test2/background.png',
        text: 'Token teste é muito da hora!',
        website: 'https://test.com',
        social: {
          telegram: 'https://t.me/test',
          twitter: 'https://twitter.com/test',
        },
      },
    ],
  },
  
  {
    id: 'moo_merlin-merlin',
    name: 'Merlin Lab',
    logo: 'single-assets/MERL.svg',
    token: 'mooMerlinMERL',
    tokenDecimals: 18,
    tokenAddress: '0x6261d793BdAe82842461A72B746bc18a5B7D2Bc4',
    tokenOracle: 'tokens',
    tokenOracleId: 'MERL',
    earnedToken: 'MERL',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xDA360309C59CB8C434b28A91b823344a96444278',
    earnContractAddress: '0x0c0487579b4378dbF5199d468bd3c6E8B7bB97fE',
    earnContractAbi: govPoolABI,
    earnedOracle: 'tokens',
    earnedOracleId: 'MERL',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1624819685,
    partners: [
      {
        logo: 'stake/merlin/logo.png',
        background: 'stake/merlin/background.png',
        text: "Merlin is an auto-compounding yield aggregator with an ecosystem optimized to focus on safe and sustainable maximum yield return. Merlin takes care of gas costs, APY tracking, optimal yield strategies, security with the goal of maximizing DeFi users' yield farming at the lowest possible cost (All APY & APRs displayed are inclusive of fees).",
        website: 'https://merlinlab.com/farm',
        social: {
          telegram: 'https://t.me/merlinlab',
          twitter: 'https://twitter.com/MerlinLab_',
        },
      },
    ],
  },
  */
  {
    id: 'zefi-bnb-p1',
    name: 'ZCore Finance',
    logo: 'single-assets/WBNB.png',
    token: 'zvZEFI',
    tokenDecimals: 18,
    tokenAddress: '0xb869e8b466d16f499039f377c0642e169a7ba698',
    tokenOracle: 'tokens',
    tokenOracleId: 'WBNB',
    earnedToken: 'WBNB',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    earnContractAddress: '0x814C5Be70cBe746F804ffBd1268d5F7D5D348d2C',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'WBNB',
    partnership: true,
    status: 'closed',
    isMooStaked: false,
    periodFinish: 1625959634,
    buyTokenUrl: '/vault/zefi-zefi',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  {
    id: 'zefi-bnb-p2',
    name: 'ZCore Finance',
    logo: 'single-assets/ETH.png',
    token: 'zvZEFIBNB',
    tokenDecimals: 18,
    tokenAddress: '0xEFb3D0152ceE0415Eb578eb0CD25ffF4c310503A',
    tokenOracle: 'tokens',
    tokenOracleId: 'ETH',
    earnedToken: 'ETH',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    earnContractAddress: '0x879223F4BDcB93467688491180185C6e5E65fC6e',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ETH',
    partnership: true,
    status: 'closed',
    isMooStaked: false,
    periodFinish: 1626236542,
    buyTokenUrl: '/vault/zefiv2-zefi-bnb',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },
  {
    id: 'zefi-bnb-p22',
    name: 'ZCore Finance',
    logo: 'degens/ZEFI-BNB.png',
    token: 'zvZEFIBNB',
    tokenDecimals: 18,
    tokenAddress: '0xEFb3D0152ceE0415Eb578eb0CD25ffF4c310503A',
    tokenOracle: 'tokens',
    tokenOracleId: 'zefiv2-zefi-bnb',
    earnedToken: 'WBNB',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    earnContractAddress: '0x334EFe6c1d078ea1A97aB447862270443a094d43',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'WBNB',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1626930129,
    buyTokenUrl: '/vault/zefi-zefi',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  {
    id: 'zefi-zbo-p2',
    name: 'ZCore Finance',
    logo: 'single-assets/ZEFI.png',
    token: 'zvZEFI',
    tokenDecimals: 18,
    tokenAddress: '0xB869e8b466d16f499039F377C0642e169a7bA698',
    tokenOracle: 'tokens',
    tokenOracleId: 'ZEFI',
    earnedToken: 'ZBO',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x7D3550d0B0aC3590834cA6103907CD6Dd41318f8',
    earnContractAddress: '0x4B9520de18314e4325889E47d4114BC6DeeFD949',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZBO',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1629062197,
    buyTokenUrl: '/vault/zefi-zefi',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  /* 15 de agosto de 2021 */

  {
    id: 'zefi-zbo-p3',
    name: 'ZCore Finance',
    logo: 'single-assets/ZEFI.png',
    token: 'zvZEFI',
    tokenDecimals: 18,
    tokenAddress: '0xB869e8b466d16f499039F377C0642e169a7bA698',
    tokenOracle: 'tokens',
    tokenOracleId: 'ZEFI',
    earnedToken: 'ZBO',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x7D3550d0B0aC3590834cA6103907CD6Dd41318f8',
    earnContractAddress: '0x2a44c3C397aF0c5fb28F6dF4137FEaB325eA3576',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZBO',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1631671316,
    buyTokenUrl: '/vault/zefi-zefi',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  {
    id: 'zbo-bnb-lp',
    name: 'ZCore Finance',
    logo: 'degens/ZBO-BNB.png',
    token: 'ZBO-BNB',
    tokenDecimals: 18,
    tokenAddress: '0x0418dE230ed174D99e80cE6cBC398cc97CD31756',
    tokenOracle: 'lps',
    tokenOracleId: 'zbo-bnb',
    earnedToken: 'ZBO',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x7D3550d0B0aC3590834cA6103907CD6Dd41318f8',
    earnContractAddress: '0xD9ce4f41C65316CF44Eed1f96d4aBd221d525653',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZBO',
    partnership: false,
    status: 'closed',
    isMooStaked: false,
    periodFinish: 1631671751,
    buyTokenUrl:
      'https://exchange.zcore.network/#/add/BNB/0x7D3550d0B0aC3590834cA6103907CD6Dd41318f8',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  {
    id: 'zefi-zbo-lp',
    name: 'ZCore Finance',
    logo: 'degens/ZEFI-ZBO.png',
    token: 'zvZEFI-ZBO',
    tokenDecimals: 18,
    tokenAddress: '0x930d156D8Aaa86C9C3E6176dF24a30D11bAf601A',
    tokenOracle: 'lps',
    tokenOracleId: 'zefi-zbo',
    earnedToken: 'ZBO',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x7D3550d0B0aC3590834cA6103907CD6Dd41318f8',
    earnContractAddress: '0x1e494CA33007cE70B3315fc82413B809d368a614',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZBO',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1631672063,
    buyTokenUrl: '/vault/zefi-zbo',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  {
    id: 'zefi-zbo-lp2',
    name: 'ZCore Finance',
    logo: 'degens/ZEFI-ZBO.png',
    token: 'zvZEFI-ZBO',
    tokenDecimals: 18,
    tokenAddress: '0x930d156D8Aaa86C9C3E6176dF24a30D11bAf601A',
    tokenOracle: 'lps',
    tokenOracleId: 'zefi-zbo',
    earnedToken: 'ZBO',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x7D3550d0B0aC3590834cA6103907CD6Dd41318f8',
    earnContractAddress: '0x6c204FF7A5C78f533527D9044B522327d61C32d4',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZBO',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1635515847,
    buyTokenUrl: '/vault/zefi-zbo',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  /* END */

  {
    id: 'goal-bnb-ula1',
    name: 'Fanatics Finance',
    logo: 'degens/GOAL-BNB.png',
    token: 'zvGOALBNB',
    tokenDecimals: 18,
    tokenAddress: '0x4d9639904e808dB560a3D06A98289F508A88420D',
    tokenOracle: 'tokens',
    tokenOracleId: 'goal-goal-bnb',
    earnedToken: 'ULA',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x5ced02730f98554c7a64d7365788cf7b2c549b2b',
    earnContractAddress: '0x6147B20cbf85e16b3993933775bD0A5958Fa4f31',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ULA',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1634241472,
    buyTokenUrl: '/vault/goal-goal-bnb',
    partners: [
      {
        logo: 'stake/fanatics/logo.png',
        background: 'stake/fanatics/background.png',
        text: 'Fanatics is a exclusive DeFi for fan tokens, football club tokens and sports entities. All football fanatics now have their place reserved in the DeFi gallery!',
        website: 'https://fanaticsfinance.com/',
        social: {
          telegram: 'https://t.me/fanaticsfinance_EN',
          twitter: 'https://twitter.com/fanaticsfinance',
        },
      },
    ],
  },

  {
    id: 'ula-axs',
    name: 'Fanatics Finance',
    logo: 'single-assets/ULA.png',
    token: 'ULA',
    tokenDecimals: 18,
    tokenAddress: '0x5ced02730f98554c7a64d7365788cf7b2c549b2b',
    tokenOracle: 'tokens',
    tokenOracleId: 'ULA',
    earnedToken: 'AXS',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x715D400F88C167884bbCc41C5FeA407ed4D2f8A0',
    earnContractAddress: '0x1BeE03Ac5727E1290Ba22F6C2AdA2AA5ec46d078',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'AXS',
    partnership: true,
    status: 'closed',
    isMooStaked: false,
    periodFinish: 1630953334,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=BNB&outputCurrency=0x5ced02730f98554c7a64d7365788cf7b2c549b2b',
    partners: [
      {
        logo: 'stake/fanatics/logo.png',
        background: 'stake/fanatics/background.png',
        text: 'Fanatics is a exclusive DeFi for fan tokens, football club tokens and sports entities. All football fanatics now have their place reserved in the DeFi gallery!',
        website: 'https://fanaticsfinance.com/',
        social: {
          telegram: 'https://t.me/fanaticsfinance_EN',
          twitter: 'https://twitter.com/fanaticsfinance',
        },
      },
    ],
  },

  {
    id: 'ula-bnb',
    name: 'Fanatics Finance',
    logo: 'single-assets/ULA.png',
    token: 'ULA',
    tokenDecimals: 18,
    tokenAddress: '0x5ced02730f98554c7a64d7365788cf7b2c549b2b',
    tokenOracle: 'tokens',
    tokenOracleId: 'ULA',
    earnedToken: 'WBNB',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    earnContractAddress: '0xE95c1566b912dd2301d00b0B23d06EE07A1Bac44',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'WBNB',
    partnership: true,
    status: 'closed',
    isMooStaked: false,
    periodFinish: 1627763239,
    buyTokenUrl:
      'https://exchange.pancakeswap.finance/#/swap?inputCurrency=BNB&outputCurrency=0x5ced02730f98554c7a64d7365788cf7b2c549b2b',
    partners: [
      {
        logo: 'stake/fanatics/logo.png',
        background: 'stake/fanatics/background.png',
        text: 'Fanatics is a exclusive DeFi for fan tokens, football club tokens and sports entities. All football fanatics now have their place reserved in the DeFi gallery!',
        website: 'https://fanaticsfinance.com/',
        social: {
          telegram: 'https://t.me/fanaticsfinance_EN',
          twitter: 'https://twitter.com/fanaticsfinance',
        },
      },
    ],
  },

  {
    id: 'zefi-btcb-ula1',
    name: 'Fanatics Finance',
    logo: 'degens/ZEFI-BTCB.png',
    token: 'zvZEFI-BTCB',
    tokenDecimals: 18,
    tokenAddress: '0xD398415ed07829BD55eB08793d3e3E309cD1399d',
    tokenOracle: 'tokens',
    tokenOracleId: 'zefi-btcb',
    earnedToken: 'ULA',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x5ced02730f98554c7a64d7365788cf7b2c549b2b',
    earnContractAddress: '0xF71d5A4FbbD48Fd32DD1D8BF75a4E495EA8Ed6F6',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ULA',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1629060739,
    buyTokenUrl: '/vault/zefi-btcb',
    partners: [
      {
        logo: 'stake/fanatics/logo.png',
        background: 'stake/fanatics/background.png',
        text: 'Fanatics is a exclusive DeFi for fan tokens, football club tokens and sports entities. All football fanatics now have their place reserved in the DeFi gallery!',
        website: 'https://fanaticsfinance.com/',
        social: {
          telegram: 'https://t.me/fanaticsfinance_EN',
          twitter: 'https://twitter.com/fanaticsfinance',
        },
      },
    ],
  },

  {
    id: 'zefi-bnb-mel',
    name: 'Caramel Finance',
    logo: 'degens/ZEFI-BNB.png',
    token: 'zvZEFIBNB',
    tokenDecimals: 18,
    tokenAddress: '0xEFb3D0152ceE0415Eb578eb0CD25ffF4c310503A',
    tokenOracle: 'tokens',
    tokenOracleId: 'zefiv2-zefi-bnb',
    earnedToken: 'MEL',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x7D5bc7796fD62a9A27421198fc3c349B96cDD9Dc',
    earnContractAddress: '0xD32c89A6F03Be849dCEFe958Dd4d90b1039A3a02',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'MEL',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1629060739,
    buyTokenUrl: '/vault/zefiv2-zefi-bnb',
    partners: [
      {
        logo: 'stake/caramel/logo.png',
        background: 'stake/caramel/background.png',
        text: 'CaramelSwap is the most complete yield farm and AMM decentralized exchange running on Binance Smart Chain with lots of unique and creative features that let you earn and win.',
        website: 'https://caramelswap.finance/',
        social: {
          telegram: 'https://t.me/CaramelFinance',
          twitter: 'https://twitter.com/caramel_finance',
        },
      },
    ],
  },

  {
    id: 'zefi-eth-bfzi',
    name: 'Bitzyon Finance',
    logo: 'degens/ZEFI-ETH.png',
    token: 'zvZEFI-ETH',
    tokenDecimals: 18,
    tokenAddress: '0x64B70aE347b30b52365EA8233368E21e8ac4f982',
    tokenOracle: 'tokens',
    tokenOracleId: 'zefi-eth',
    earnedToken: 'BZFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x3E771544892863Fb0B7c048A07537Ad29E932c56',
    earnContractAddress: '0xfabf56f351Ab78127aE32017d4146EC8f79A13f4',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'BZFI',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1629060739,
    buyTokenUrl: '/vault/zefi-eth',
    partners: [
      {
        logo: 'stake/bitzyon/logo.png',
        background: 'stake/bitzyon/background.png',
        text: 'BitZyon Finance (BZFI) was created with the sole purpose of maximizing investments in a safe and sustainable ecosystem.',
        website: 'https://bitzyon.com/finance',
        social: {
          telegram: 'https://t.me/BitZyonFinanceOfficial',
          twitter: 'https://twitter.com/bitzyonfinance',
        },
      },
    ],
  },

  {
    id: 'seed-axs',
    name: 'ZCore Finance',
    logo: 'single-assets/SEED.png',
    token: 'zvSEED',
    tokenDecimals: 18,
    tokenAddress: '0xae8741D5A735f9D77a572E9e7DF5496Deefe9631',
    tokenOracle: 'tokens',
    tokenOracleId: 'SEED',
    earnedToken: 'AXS',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x715D400F88C167884bbCc41C5FeA407ed4D2f8A0',
    earnContractAddress: '0x93D40C32112fa2ED196ee5a963c7F5998D1EBb98',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'AXS',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1629062197,
    buyTokenUrl: '/vault/seed-seed',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  {
    id: 'seed-zefi',
    name: 'ZCore Finance',
    logo: 'single-assets/SEED.png',
    token: 'zvSEED',
    tokenDecimals: 18,
    tokenAddress: '0xae8741D5A735f9D77a572E9e7DF5496Deefe9631',
    tokenOracle: 'tokens',
    tokenOracleId: 'SEED',
    earnedToken: 'ZEFI',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
    earnContractAddress: '0xa9d2701968024224027cD096dD31F4B114BD993E',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ZEFI',
    partnership: true,
    status: 'closed',
    isMooStaked: true,
    periodFinish: 1629062197,
    buyTokenUrl: '/vault/seed-seed',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  /*
  {
    id: 'dont-zveth',
    name: 'Dont Panic',
    logo: 'single-assets/DONT.png',
    token: 'zvZEFI-ETH',
    tokenDecimals: 18,
    tokenAddress: '0x64B70aE347b30b52365EA8233368E21e8ac4f982',
    tokenOracle: 'tokens',
    tokenOracleId: 'DONT',
    earnedToken: 'DONT',
    earnedTokenDecimals: 9,
    earnedTokenAddress: '0xEC3B14C16D3Bec0a13C93397fc9b4E7a15Cd0f0B',
    earnContractAddress: '0x053aBacF17862eeDCe621F9098b9826F8C0155ED',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'DONT',
    partnership: true,
    status: 'closed',
    isMooStaked: false,
    periodFinish: 1625639265,
    buyTokenUrl: '/vault/zefi-eth',
    partners: [
      {
        logo: 'stake/dont/logo.png',
        background: 'stake/dont/background.png',
        text: 'This project aims to encourage the reading of books and comic books. On the one hand, funding independent artists and publishers, on the other hand, ensuring access to literary content for low-income people.',
        website: 'https://dontpanic.club/',
        social: {
          telegram: 'https://t.me/DontPanicToken',
          twitter: 'https://twitter.com/DontPanicToken',
        },
      },
    ],
  },

  

  {
    id: 'dont-dont',
    name: 'Dont Panic',
    logo: 'single-assets/DONT.png',
    token: 'DONT-BNB',
    tokenDecimals: 9,
    tokenAddress: '0xEC3B14C16D3Bec0a13C93397fc9b4E7a15Cd0f0B',
    tokenOracle: 'tokens',
    tokenOracleId: 'DONT',
    earnedToken: 'DONT',
    earnedTokenDecimals: 9,
    earnedTokenAddress: '0xEC3B14C16D3Bec0a13C93397fc9b4E7a15Cd0f0B',
    earnContractAddress: '0x7fd87cC8d5e716F897811917a04ca41683D755aA',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'DONT',
    partnership: false,
    status: 'closed',
    isMooStaked: false,
    periodFinish: 1625639265,
    buyTokenUrl: 'https://exchange.pancakeswap.finance/#/add/BNB/0xEC3B14C16D3Bec0a13C93397fc9b4E7a15Cd0f0B',
    partners: [
      {
        logo: 'stake/dont/logo.png',
        background: 'stake/dont/background.png',
        text: 'This project aims to encourage the reading of books and comic books. On the one hand, funding independent artists and publishers, on the other hand, ensuring access to literary content for low-income people.',
        website: 'https://dontpanic.club/',
        social: {
          telegram: 'https://t.me/DontPanicToken',
          twitter: 'https://twitter.com/DontPanicToken',
        },
      },
    ],
  },


  {
    id: 'zefi-1inch-p1',
    name: 'ZCore Finance',
    logo: 'single-assets/1INCH.png',
    token: 'ZEFI',
    tokenDecimals: 18,
    tokenAddress: '0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
    tokenOracle: 'tokens',
    tokenOracleId: '1INCH',
    earnedToken: '1INCH',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x111111111117dc0aa78b770fa6a738034120c302',
    earnContractAddress: '0xA35ba301e01BeE2E66A56D5c97B8a9BebBC9cA43',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: '1INCH',
    partnership: false,
    status: 'closed',
    isMooStaked: false,
    periodFinish: 1625639265,
    buyTokenUrl: 'https://exchange.zcore.network/#/swap?outputCurrency=0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },

  {
    id: 'zefi-ada-p1',
    name: 'ZCore Finance',
    logo: 'single-assets/ADA.png',
    token: 'ZEFI',
    tokenDecimals: 18,
    tokenAddress: '0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
    tokenOracle: 'tokens',
    tokenOracleId: 'ADA',
    earnedToken: 'ADA',
    earnedTokenDecimals: 18,
    earnedTokenAddress: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
    earnContractAddress: '0x0b339d97357D8F8B7ea994F842E3567aB2766870',
    earnContractAbi: govPoolABIZefi,
    earnedOracle: 'tokens',
    earnedOracleId: 'ADA',
    partnership: false,
    status: 'closed',
    isMooStaked: false,
    periodFinish: 1625639265,
    buyTokenUrl: 'https://exchange.zcore.network/#/swap?outputCurrency=0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
    partners: [
      {
        logo: 'stake/zcore/logo.png',
        background: 'stake/zcore/background.png',
        text: 'ZCore Finance is a decentralized exchange running on Binance Smart Chain and Pancake swap exchange, with features that let you earn and win tokens. What we are trying to do is create a perpetual deflation token, the ZEFI, that allows a constant price pump with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the system and create a suitable and sustainable environment for people to yield farm with high APR.',
        website: 'https://finance.zcore.network/',
        social: {
          telegram: 'https://t.me/ZCoreFinance',
          twitter: 'https://twitter.com/ZCoreCrypto',
        },
      },
    ],
  },
*/
];
