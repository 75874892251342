import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import BigNumber from 'bignumber.js';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core/Box';
import { shadows } from '@material-ui/system';
import { formatTvl } from 'features/helpers/format';
import { byDecimals } from 'features/helpers/bignumber';
import styles from './styles';
import PoolPaused from './PoolPaused/PoolPaused';
import PoolTitle from './PoolTitle/PoolTitle';
import LabeledStat from './LabeledStat/LabeledStat';
import ApyStats from './ApyStats/ApyStats';
import { useLaunchpoolSubscriptions } from '../../../stake/redux/hooks';
import { usePoolApr } from '../../../stake/redux/subscription';
import { PoolBoosts } from './PoolBoosts/PoolBoosts';
import { launchpools } from '../../../helpers/getNetworkData';

const useStyles = makeStyles(styles);

const PoolSummary = ({
  pool,
  launchpool,
  toggleCard,
  balanceSingle,
  sharesBalance,
  apy,
  fetchBalancesDone,
  fetchApysDone,
  fetchVaultsDataDone,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  // Subscribe to updates for this pool
  const { subscribe } = useLaunchpoolSubscriptions();

  // const launchpoolAll = useSelector(state => state.vault.vaultLaunchpoolsAll);
  // const launchpoolAprAll = usePoolApr(Object.values(launchpoolAll[pool.id])[1] ? Object.values(launchpoolAll[pool.id])[1].id : null);

  /*
useEffect(() => {

 if(Object.values(launchpoolAll[pool.id])){
  if(Object.values(launchpoolAll[pool.id]).length > 0){
   console.log('########################################################')
   console.log('---> '+ pool.id + ' ('+ Object.values(launchpoolAll[pool.id]).length +')')
   for (let i = 0; i < Object.values(launchpoolAll[pool.id]).length; i = i + 1) {   
     const launchpoolId = Object.values(launchpoolAll[pool.id])[i] ? Object.values(launchpoolAll[pool.id])[i].id : null

    if (launchpoolId) {
      return subscribe(launchpoolId, {
        poolApr: true,
        poolFinish: true,
      });
    }

     
     console.log(launchpoolId)
   }  
   // console.log(launchpoolAprAll)
   // console.log(Object.values(launchpoolAll[pool.id]).length)
   console.log('########################################################')
  }
}

}, [subscribe, pool]); 
*/

if(apy.totalApy > 9999999.99){
  apy.totalApy = 9999999.99
}

  if (launchpool) {
    // console.log(launchpool.id)
    // console.log(launchpool)
    // console.log(launchpoolAll[launchpool.id])
  }

  const launchpoolApr = usePoolApr(launchpool ? launchpool.id : null);
  if (launchpool) {
    // console.log(launchpool.id)
    // console.log(launchpoolApr)
  }

  /*
    for (let i = 0; i < Object.values(launchpoolAll).length; i = i + 1) {   
      if(Object.values(launchpoolAll)[i].length > 0){
        // console.log(Object.values(launchpoolAll)[i].length)
      }
    }
*/

  // const launchpoolAprAll = usePoolApr(launchpool ? launchpool.id : null);

  const vaultStateTitle = useMemo(() => {
    let state =
      pool.status === 'eol'
        ? t('Vault-DepositsRetiredTitle')
        : pool.depositsPaused
        ? t('Vault-DepositsPausedTitle')
        : null;

    if (launchpool) {
      state = `🚀 Power by ${launchpool.name}`;
    }

    if (pool.experimental) {
      state = t('Vault-Experimental');
    }

    // if (pool.turbo) {
    //   state = '🔥 Turbo Vault';
    //}

    return state === null ? (
      ''
    ) : (
      <PoolPaused
        message={t(state)}
        isBoosted={!!launchpool}
        isExperimental={!!pool.experimental}
        isTurbo={!!pool.turbo}
      />
    );
  }, [pool, launchpool, t]);

  const balanceUsd =
    balanceSingle > 0 && fetchVaultsDataDone ? formatTvl(balanceSingle, pool.oraclePrice) : '';
  const deposited = byDecimals(
    sharesBalance.multipliedBy(new BigNumber(pool.pricePerFullShare)),
    pool.tokenDecimals
  );
  const depositedUsd =
    deposited > 0 && fetchVaultsDataDone ? formatTvl(deposited, pool.oraclePrice) : '';

  const onSummaryClick = useCallback(
    e => {
      if (!e.target || !e.target.classList.contains('tooltip-toggle')) {
        toggleCard();
      }
    },
    [toggleCard]
  );

  const wellgrid = {
    paddingTop: '20px',
    elevation: 0,
  };

  const wellparent = {
    backgroundColor: '#FF0000',
  };

  // const { subscribe } = useLaunchpoolSubscriptions();
  const poolName = pool.name;
  const earnedTokenAddress = pool.earnedTokenAddress;

  // Find launchpools that ended less than 30 days ago
  const recentLaunchpools = useMemo(() => {
    const nowMinusDays = Date.now() / 1000 - 86400 * 30;
    return Object.values(launchpools).filter(
      lp => lp.tokenAddress === earnedTokenAddress && lp.periodFinish > nowMinusDays
    );
  }, [earnedTokenAddress]);

  // Subscribe to user staked for those pools
  useEffect(() => {
    const unsubscribes = recentLaunchpools.map(launchpool =>
      subscribe(launchpool.id, {
        userStaked: true,
      })
    );

    return () => unsubscribes.forEach(unsubscribe => unsubscribe());
  }, [subscribe, recentLaunchpools]);

  // Get user staked for those pools (if not zero)
  const recentStaked = useSelector(
    state =>
      Object.fromEntries(
        recentLaunchpools
          .map(lp => [
            lp.id,
            state.stake.userStaked[lp.id] && state.stake.userStaked[lp.id] !== '0'
              ? byDecimals(state.stake.userStaked[lp.id], lp.tokenDecimals)
              : null,
          ])
          .filter(([, staked]) => !!staked)
      ),
    shallowEqual
  );

  // Count
  const numStakedLaunchpools = Object.keys(recentStaked).length;

  let labelMatic = t('Vault-Deposited');
  let labelMaticValue = depositedUsd;

  if (pool.id === 'zefi-matic' || pool.id === 'cake-cakev2') {
    if (numStakedLaunchpools == 0 && deposited > 0) {
      // console.log('mostra erro!')
      labelMatic = '⚠️ NO REWARD';
      labelMaticValue = '';
    } else {
      // console.log('nao tem deposito...')
    }
  }


  if(deposited.toNumber() > 0){
    labelMatic = <img alt="money" src={require(`images/money.svg`)} width={'24px'} height={'24px'} />;
  }

  //console.log(deposited.toNumber());
  //console.log(formatDecimals(deposited));

  return (
    <AccordionSummary onClick={onSummaryClick} className={classes.well}>
      <Grid container alignItems="center" style={wellgrid}>
        {vaultStateTitle}
        <PoolBoosts poolName={pool.name} earnedTokenAddress={pool.earnedTokenAddress} />
        <Grid item xs={12} className={`${classes.item} ${classes.itemTitle}`}>
          <PoolTitle
            name={pool.name}
            logo={pool.logo}
            poolId={pool.id}
            description={t('Vault-Description', { vault: pool.tokenDescription })}
            launchpool={launchpool}
            addLiquidityUrl={pool.addLiquidityUrl}
            removeLiquidityUrl={pool.removeLiquidityUrl}
            buyTokenUrl={pool.buyTokenUrl}
            assets={pool.assets}
            recentLaunchpools={recentLaunchpools}
          />
        </Grid>
        <Grid
          item
          xs={6}
          className={`${classes.mobilePadding} ${classes.item} ${classes.itemBalances}`}
        >
          <LabeledStat
            value={formatDecimals(balanceSingle)}
            subvalue={balanceUsd}
            label={t('Vault-Wallet')}
            isLoading={!fetchBalancesDone}
            className={classes.itemInner}
          />
        </Grid>
        <Grid
          item
          xs={6}
          className={`${classes.mobilePadding} ${classes.item} ${classes.itemBalances}`}
        >
          {
          !numStakedLaunchpools ? (
            <LabeledStat
              value={formatDecimals(deposited)}
              subvalue={labelMaticValue}
              label={labelMatic}
              isLoading={!fetchBalancesDone}
              className={classes.itemInner}
            />
          ) : (
            <img alt="money" src={require(`images/money.svg`)} width={'48px'} height={'48px'} />
          )}
        </Grid>
        <ApyStats
          pool={pool}
          apy={apy}
          launchpoolApr={launchpoolApr}
          isLoading={!fetchApysDone}
          itemClasses={`${classes.item} ${classes.itemStats}`}
          itemInnerClasses={classes.itemInner}
        />
        <Grid item xs={4} className={`${classes.item} ${classes.itemStats}`}>
          <LabeledStat
            value={formatTvl(pool.tvl, pool.oraclePrice)}
            label={t('Vault-TVL')}
            isLoading={!fetchVaultsDataDone}
            className={classes.itemInner}
          />
        </Grid>
      </Grid>
    </AccordionSummary>
  );
};

const formatDecimals = number => {
  return number >= 10 ? number.toFixed(4) : number.isEqualTo(0) ? 0 : number.toFixed(8);
};

export default PoolSummary;
