import React, { memo, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import { formatApy } from '../../../../helpers/format';
import { isNaN } from '../../../../helpers/bignumber';
import LabeledStat from '../LabeledStat/LabeledStat';
import { Fade, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(styles);

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const yearlyToDaily = apy => {
  const g = Math.pow(10, Math.log10(apy + 1) / 365) - 1;

  if (isNaN(g)) {
    return 0;
  }

  return g;
};

const BreakdownTooltip = memo(({ rows }) => {
  const classes = useStyles();

  return (
    <table>
      <tbody>
        {rows.map(row => (
          <tr key={row.label}>
            <th className={classes.label}>{row.label}</th>
            <td className={classes.value}>{row.value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
});

const YearlyBreakdownTooltip = memo(({ rates }) => {
  const rows = [];
  const { t } = useTranslation();

  if ('vaultApr' in rates) {
    rows.push({
      label: t('Vault-Breakdown-VaultApr'),
      value: rates.vaultApr,
    });
  }

  if ('tradingApr' in rates) {
    rows.push({
      label: t('Vault-Breakdown-TradingApr'),
      value: rates.tradingApr,
    });
  }

  if ('boostApr' in rates) {
    rows.push({
      label: t('Power APR'),
      value: rates.boostApr,
    });
  }

  return <BreakdownTooltip rows={rows} />;
});

const DailyBreakdownTooltip = memo(({ rates }) => {
  const rows = [];
  const { t } = useTranslation();

  if ('vaultDaily' in rates) {
    rows.push({
      label: t('Vault-Breakdown-VaultDaily'),
      value: rates.vaultDaily,
    });
  }

  if ('tradingDaily' in rates) {
    rows.push({
      label: t('Vault-Breakdown-TradingDaily'),
      value: rates.tradingDaily,
    });
  }

  if ('boostDaily' in rates) {
    rows.push({
      label: t('Power Daily'),
      value: rates.boostDaily,
    });
  }

  return <BreakdownTooltip rows={rows} />;
});

const LabeledStatWithTooltip = memo(({ tooltip, label, ...passthrough }) => {
  const classes = useStyles();

  return tooltip ? (
    <Tooltip
      arrow
      TransitionComponent={Fade}
      title={tooltip}
      placement="bottom"
      enterTouchDelay={0}
      leaveTouchDelay={3000}
      classes={{ tooltip: classes.tooltip }}
    >
      <LabeledStat
        label={
          <>
            {label} <i className="fas fa-info-circle" />
          </>
        }
        {...passthrough}
      />
    </Tooltip>
  ) : (
    <LabeledStat label={label} {...passthrough} />
  );
});

const ApyStats = ({
  pool,
  apy,
  launchpoolApr,
  isLoading = false,
  itemClasses,
  itemInnerClasses,
}) => {
  const { t } = useTranslation();
  let isBoosted = !!launchpoolApr;
  const values = {};
  let needsApyTooltip = false;
  let needsDailyTooltip = false;

  values.totalApy = apy.totalApy;

  if ('vaultApr' in apy && apy.vaultApr) {
    needsApyTooltip = true;
    values.vaultApr = apy.vaultApr;
    values.vaultDaily = apy.vaultApr / 365;
  }

  if ('tradingApr' in apy && apy.tradingApr) {
    needsApyTooltip = true;
    needsDailyTooltip = true;
    values.tradingApr = apy.tradingApr;
    values.tradingDaily = apy.tradingApr / 365;
  }

  if ('vaultAprDaily' in values || 'tradingAprDaily' in values) {
    values.totalDaily = (values.vaultDaily || 0) + (values.tradingDaily || 0);
  } else {
    values.totalDaily = yearlyToDaily(values.totalApy);
  }

  if (isBoosted) {
    needsApyTooltip = true;
    needsDailyTooltip = true;
    values.boostApr = launchpoolApr;
    values.boostDaily = launchpoolApr / 365;
    if (pool.turbo) {
      values.boostedTotalApy = values.boostApr ? values.boostApr : 0;
      values.boostedTotalDaily = values.boostDaily ? values.boostDaily : 0;
    } else {
      values.boostedTotalApy = values.boostApr ? values.totalApy + values.boostApr : 0;
      values.boostedTotalDaily = values.boostDaily ? values.totalDaily + values.boostDaily : 0;
    }
  }

  const formatted = Object.fromEntries(
    Object.entries(values).map(([key, value]) => [key, formatApy(value)])
  );

  // ####################################
  // # ZCORE
  // ####################################

  let nvault = '-';
  let napy = 0;
  let napyday = 0;

  if (pool) {
    if (pool.token === 'ZEFI-BNB') {
      /*
      console.log('##################################')
      console.log(pool.token)
      console.log( JSON.stringify(values) )
      console.log('##################################')
      */
    }
    nvault = pool.token;
    if (isBoosted) {
      /*      
      console.log('#######################')
      console.log(pool.token)
      console.log(formatted.totalApy)
      console.log(formatted.boostedTotalApy)
      console.log(values.boostedTotalApy)
      console.log(values.totalApy)
      console.log('#######################')
*/
      // NÃO USA APY DO POWER
      // napy = values.boostedTotalApy * 100;
      // napyday = values.boostedTotalDaily * 100;

      napy = values.totalApy * 100;
      // napy = localStorage.getItem('apy_tcake_zefi');
      napyday = values.totalDaily * 100;
    } else {
      // console.log(pool.token)
      // console.log(formatted.totalApy)
      napy = values.totalApy * 100;
      napyday = values.totalDaily * 100;
    }
    if (values.totalApy === 0) {
      napy = 0;
      napyday = 0;
    }
  }

  let newApy = formatted.totalApy;
  if (formatted.totalApy === '???') {
    newApy = formatted.boostedTotalApy;
  }

  let newDailyApy = formatted.totalDaily;
  if (formatted.totalDaily === '???') {
    newDailyApy = formatted.boostedTotalDaily;
  }

  let labelVault = t('Vault-APY');
  if (pool.id === 'zefi-matic') {
    isBoosted = false;
    labelVault = 'APR';
  }

  if (pool.turbo) {
    // newApy = formatted.boostedTotalApy;
    newDailyApy = formatted.boostedTotalDaily;

    const keyzefi = localStorage.getItem(`zefi_apy_day`);
    // console.log('ZEFI : ' + keyzefi)
    // console.log('ZEFI : ' + napyday)

    let dailyStakingReward = napyday / (1 * 100); // POOL
    let dailyPacocaReward = keyzefi / (1 * 100); // ZEFI

    let sweetAPY = dailyStakingReward;

    for (const i of Array(364))
      sweetAPY = sweetAPY + sweetAPY * dailyPacocaReward + dailyStakingReward;

    // console.log('ZEFI : ' + sweetAPY * 100)

    newApy = `${(sweetAPY * 100).toFixed(2)}%`;
    napy = (sweetAPY * 100).toFixed(2);

    // FIX TURBO
    newApy = `${(localStorage.getItem('apy_tcake_zefi') * 1).toFixed(2)}%`;

    // console.log('pool.id : ' + pool.id)
    // console.log('newApy : ' + localStorage.getItem('apy_tcake_zefi'))
    // console.log('newApy : ' + newApy)

    isBoosted = false;
    // labelVault = 'APR';

    // REMOVE CAKE
    if (pool.id == 'cake-cakev2') {
      newApy = '-';
    }
  }

  const segundos = 5;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const keydbAll = `update_apy`;
        const zefiDb = localStorage.getItem(keydbAll);
        let tempData;

        const agora = new Date().getTime();
        let ultimo;

        if (zefiDb === null) {
          ultimo = new Date().getTime();
        } else {
          ultimo = Number(zefiDb);
        }

        /*
        if (nvault === 'CAKE'){
          console.log('##################################')
          console.log(localStorage.getItem('apy_tcake_zefi'))
          console.log(localStorage.getItem('apy_tcake_zbo'))
          console.log('##################################')
        }
*/
        const diferenca = (agora - ultimo) / 1000;
        /*
          console.log('##################################')
          console.log(agora)
          console.log(ultimo)
          console.log(diferenca)
          console.log('##################################')
        */

        if (zefiDb === null || diferenca > segundos) {
          if (napy !== '???') {
            // localStorage.setItem(keydbAll, String(agora) );  // key and value both should be string or number;
          }

          if (napy > 0) {
            if (nvault === 'ZEFI') {
              const keyzefi = `zefi_apy_day`;
              // const zefiDbZ = localStorage.getItem(keyzefi);
              localStorage.setItem(keyzefi, napyday); // key and value both should be string or number;
              // console.log(localStorage.getItem(keyzefi))
            }

            if (nvault === 'ZBO') {
              // console.log(localStorage.getItem('zeficard-zvzbo'));
              const random = getRandomInt(1, 2);
              if (random === 2) {
                const keyzefi = `zbo_apy_day`;
                // const zefiDbZ = localStorage.getItem(keyzefi);
                localStorage.setItem(keyzefi, napyday); // key and value both should be string or number;
              } else {
                nvault = 'ZEFICARD';
                napy = localStorage.getItem('zeficard-zvzbo');
              }
            }

            if ((nvault === 'CAKE' && formatted.boostDaily !== undefined) || nvault !== 'CAKE') {
              if (nvault === 'CAKE') {
                const random = getRandomInt(1, 2);
                if (random === 2) {
                  nvault = 'CAKEZBO';
                  napy = localStorage.getItem('apy_tcake_zbo');
                } else {
                  nvault = 'CAKE';
                  napy = localStorage.getItem('apy_tcake_zefi');
                }

                /*
                console.log('##################################')
                console.log(random)
                console.log(nvault)
                console.log(napy)
                console.log('##################################')                
*/
              }

              /*
              console.log('##################################')
              console.log('Atualiza API!!!')
              console.log('##################################')
              console.log(nvault)
              console.log(napyday)
              console.log(napy)
              console.log('##################################')            
*/

              const response = await fetch(`https://site.zcore.fi/cron_apy.php`, {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  apy: napy,
                  vault: nvault,
                }),
              });
              const responsedata = await response.json();
              // console.log(responsedata)
            }
          }

          // setData(responsedata)
          // console.log(responsedata)
        } else {
          // console.log(`Aguarda API: ${diferenca}`)
        }
      } catch (error) {
        // console.error('Unable to fetch data:', error)
      }
    };

    fetchData();
  }, [nvault, napy, napyday, isBoosted]);

  return (
    <>
      <Grid item xs={4} className={itemClasses}>
        <LabeledStatWithTooltip
          value={newApy}
          label={labelVault}
          tooltip={
            !isLoading && needsApyTooltip ? <YearlyBreakdownTooltip rates={formatted} /> : null
          }
          boosted={isBoosted ? formatted.boostedTotalApy : ''}
          isLoading={isLoading}
          className={`tooltip-toggle ${itemInnerClasses}`}
        />
      </Grid>
      <Grid item xs={4} className={itemClasses}>
        <LabeledStatWithTooltip
          value={newDailyApy}
          label={t('Vault-APYDaily')}
          tooltip={
            !isLoading && needsDailyTooltip ? <DailyBreakdownTooltip rates={formatted} /> : null
          }
          boosted={isBoosted ? formatted.boostedTotalDaily : ''}
          isLoading={isLoading}
          className={`tooltip-toggle ${itemInnerClasses}`}
        />
      </Grid>
    </>
  );
};

export default memo(ApyStats);
