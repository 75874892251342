const styles = theme => ({
  container: {
    marginBottom: '0px',
  },
  accordion: {
    width: '100%',
    margin: '5px',
    backgroundColor: theme.palette.background.primary,
  },
  divider: {
    margin: '0 30px',
  },
  wellparent: {
    backgroundColor: theme.palette.background.secondary,
    justifyContent: 'space-between',
    marginTop: '5px',
    borderRadius: '20px',
  },
});

export default styles;
