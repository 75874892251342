import React from 'react';
import {
  Grid,
  Typography,
  Avatar,
  makeStyles,
  Box,
  Accordion,
  AccordionDetails,
} from '@material-ui/core';
import styles from './list';
import Pools from 'features/vault/components/Pools/Pools';
import Disclaimer from 'components/Disclaimer/Disclaimer';

const useStyles = makeStyles(styles);

export default function HomePage() {
  const classes = useStyles();
  return (
    <>
      {/*
      <Grid item xs={12}>
        <div style={{ marginTop: '10px' }} className={classes.launchpool}>
          <img alt="Launchpool" src={require('images/vaults.png')} />
        </div>
      </Grid>    
      */}
      <Disclaimer />
      <Pools fromPage="home" />
    </>
  );
}
