import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import Menu from '@material-ui/icons/Menu';
import Close from '@material-ui/icons/Close';
import WbSunny from '@material-ui/icons/WbSunny';
import NightsStay from '@material-ui/icons/NightsStay';
import { getNetworkBuyUrl } from '../../features/helpers/getNetworkData';
import styles from './styles';

const useStyles = makeStyles(styles);

const Header = ({ links, isNightMode, setNightMode }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  let bridgename = 'Yield Farm';
  let bridgeicon = 'fas fa-plus-square';
  let bridgelink = 'https://app.zcore.network/';
  let buylink =
    'https://pancakeswap.finance/swap?outputCurrency=0x0e8fE6eeD5342Ea9189D9268D088821f0532fb74';

  // console.log(process.env.REACT_APP_NETWORK_ID)

  if (Number(process.env.REACT_APP_NETWORK_ID) === 137) {
    bridgename = 'Bridge';
    bridgeicon = 'fa fa-external-link-square';
    bridgelink = 'https://medium.com/zcore/how-to-bridge-zefi-cross-chain-5f0abb60b695';
    buylink =
      'https://quickswap.exchange/#/swap?inputCurrency=ETH&outputCurrency=0xCaf870DaD882b00F4b20D714Bbf7fceADA5E4195';
  }

  return (
    <AppBar className={`${classes.appBar} ${classes.dark}`} position="relative">
      <Toolbar className={classes.container}>
        <Button href="/" className={classes.title}>
          <Hidden xsDown>
            <img
              alt="ZEFI"
              src={require(`images/ZCR-logo-full.png`)}
              height={'35px'}
              className={classes.logo}
            />
          </Hidden>
          <Hidden smUp>
            <img
              alt="ZEFI"
              src={require(`images/ZCR-logo.png`)}
              height={'35px'}
              className={classes.logo}
            />
          </Hidden>
        </Button>

        <div className={classes.middleNav}>
          <Hidden smDown>
            <a
              href={`/`}
              rel="noopener noreferrer"
              className={classes.link}
              style={{ marginLeft: '5px', marginRight: '5px' }}
            >
              <i className={`fas fa-university ${classes.icon}`} />
              <span>Vaults</span>
            </a>
            <a
              href={`/stake`}
              rel="noopener noreferrer"
              className={classes.link}
              style={{ marginLeft: '5px', marginRight: '5px' }}
            >
              <i className={`fas fa-rocket ${classes.icon}`} />
              <span>Pools</span>
            </a>
            <a
              href={buylink}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
              style={{ marginLeft: '5px', marginRight: '5px' }}
            >
              <i className={`fas fa-dollar-sign ${classes.icon}`} />
              <span>Buy ZCR</span>
            </a>
            <a
              href={bridgelink}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
              style={{ marginLeft: '5px', marginRight: '5px' }}
            >
              <i className={`${bridgeicon} ${classes.icon}`} />
              <span>{bridgename}</span>
            </a>
            <a
              href={`https://dao.zcore.network/`}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
              style={{ marginLeft: '5px', marginRight: '5px' }}
            >
              <i className={`fas fa-university ${classes.icon}`} />
              <span>Vote</span>
            </a>
          </Hidden>
        </div>
        {/*
        <div className={classes.middleNav}>
          <Hidden smDown>
            {renderLink('vote', 'vote', 'vote-yea', classes)}
            {renderLink('dashboard', t('stats'), 'chart-bar', classes)}
            {renderLink('docs', 'docs', 'book', classes)}
          </Hidden>
          {renderLink('buy', t('buy'), 'dollar-sign', classes)}
          {renderBoost(classes)}
        </div>
*/}

        <Hidden smDown implementation="css">
          <div className={classes.collapse}>{links}</div>
        </Hidden>
        <Hidden mdUp>
          <IconButton
            className={classes.iconButton}
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>

      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={'right'}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className={classes.closeButtonDrawer}
          >
            <Close />
          </IconButton>
          <div className={classes.appResponsive}>{links}</div>
          <div style={{ textAlign: 'center' }}>
            <div style={{ width: '100%', paddingTop: '20px' }}>
              <a
                href={`/`}
                rel="noopener noreferrer"
                className={classes.link}
                style={{ marginLeft: '5px', marginRight: '5px' }}
              >
                <i className={`fas fa-university ${classes.icon}`} />
                <span>Vaults</span>
              </a>
            </div>
            <div style={{ width: '100%', paddingTop: '20px' }}>
              <a
                href={`/stake`}
                rel="noopener noreferrer"
                className={classes.link}
                style={{ marginLeft: '5px', marginRight: '5px' }}
              >
                <i className={`fas fa-rocket ${classes.icon}`} />
                <span>Pools</span>
              </a>
            </div>
            <div style={{ width: '100%', paddingTop: '20px' }}>
              <a
                href={`https://pancakeswap.finance/swap?outputCurrency=0x0e8fE6eeD5342Ea9189D9268D088821f0532fb74`}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
                style={{ marginLeft: '5px', marginRight: '5px' }}
              >
                <i className={`fas fa-dollar-sign ${classes.icon}`} />
                <span>Buy ZCR</span>
              </a>
            </div>
            <div style={{ width: '100%', paddingTop: '20px' }}>
              <a
                href={`https://app.zcore.network/`}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
                style={{ marginLeft: '5px', marginRight: '5px' }}
              >
                <i className={`fas fa-plus-square ${classes.icon}`} />
                <span>Farm ZEFI</span>
              </a>
            </div>
            <div style={{ width: '100%', paddingTop: '20px' }}>
              <a
                href={`https://dao.zcore.network/`}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
                style={{ marginLeft: '5px', marginRight: '5px' }}
              >
                <i className={`fas fa-vote-yea ${classes.icon}`} />
                <span>Vote</span>
              </a>
            </div>
            {/*
            <LinkSidebar name="vote" label="vote" icon="vote-yea" classes={classes} />
            <LinkSidebar name="dashboard" label={t('stats')} icon="chart-bar" classes={classes} />
            <LinkSidebar name="docs" label="docs" icon="book" classes={classes} />
            <LinkSidebar name="buy" label={t('buy')} icon="dollar-sign" classes={classes} />
*/}
            <IconButton onClick={setNightMode} className={classes.icon}>
              {isNightMode ? <WbSunny /> : <NightsStay />}
            </IconButton>
          </div>
        </Drawer>
      </Hidden>
    </AppBar>
  );
};

const renderLink = (name, label, icon, classes) => {
  return (
    <a
      href={getLinkUrl(name)}
      target="_blank"
      rel="noopener noreferrer"
      className={classes.link}
      style={{ marginLeft: '5px', marginRight: '5px' }}
    >
      <i className={`fas fa-${icon} ${classes.icon}`} />
      <span>{label}</span>
    </a>
  );
};

const renderBoost = classes => {
  return (
    <a className={classes.btnBoost} href="/stake">
      <img alt="Boost" src={require('images/stake/boost.svg')} />
    </a>
  );
};

const LinkSidebar = ({ name, label, icon, classes }) => (
  <div style={{ width: '100%', paddingTop: '10px' }}>{renderLink(name, label, icon, classes)}</div>
);

const getLinkUrl = name => {
  return name === 'buy' ? getNetworkBuyUrl() : `https://${name}.beefy.finance`;
};

export default Header;
